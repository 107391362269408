import { Muscle } from 'generated'

export const muscleOptions = [
  {
    value: Muscle.Abs,
    label: 'Abs',
  },
  {
    value: Muscle.Back,
    label: 'Back',
  },
  {
    value: Muscle.Shoulders,
    label: 'Shoulders',
  },
  {
    value: Muscle.Arms,
    label: 'Arms',
  },
  {
    value: Muscle.Chest,
    label: 'Chest',
  },
  {
    value: Muscle.Legs,
    label: 'Legs',
  },
  {
    value: Muscle.Core,
    label: 'Core',
  },
  {
    value: Muscle.Biceps,
    label: 'Biceps',
  },
  {
    value: Muscle.Triceps,
    label: 'Triceps',
  },
  {
    value: Muscle.Calves,
    label: 'Calves',
  },
  {
    value: Muscle.Quads,
    label: 'Quads',
  },
]

export const getMuscleOptionLabel = (value: Muscle) => {
  return muscleOptions.find((option) => option.value === value)?.label
}
