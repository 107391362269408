import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import type { SidebarItem } from 'components'

interface UseMenuItemsProps {
  menuItems: SidebarItem[]
}

export const useMenuItems = ({ menuItems }: UseMenuItemsProps) => {
  const location = useLocation()

  const menuItemsKeys = location.search
    ? menuItems.reduce((acc, item) => {
        if (item.children) {
          const childrenKeys = item.children.map((child) => child.key)
          return [...acc, ...childrenKeys]
        }
        return [...acc, item.key]
      }, [] as string[])
    : menuItems.map((item) => {
        return item.key
      })

  const menuItemLocationExist = menuItemsKeys.find((key) => `${location.pathname}${location.search}`.includes(key))
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null)

  const [openKeys, setOpenKeys] = useState<string[] | null>(null)

  const handleClickMenuItem = useCallback(({ key }: { key: string }) => {
    setActiveMenuItem(key)
  }, [])

  useEffect(() => {
    if (menuItemLocationExist) {
      setActiveMenuItem(menuItemLocationExist)
    } else {
      setActiveMenuItem(null)
    }
  }, [menuItemLocationExist])

  useEffect(() => {
    if (location.search) {
      const key = location.search.replace('?', '').split('=')[0]
      setOpenKeys([key])
    }
  }, [location.search])

  return {
    openKeys,
    setOpenKeys,
    activeMenuItem,
    setActiveMenuItem,
    handleClickMenuItem,
  }
}
