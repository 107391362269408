import { Link as BaseLink } from 'react-router-dom'
import { styled } from 'styled-components'

export const TermsWrapper = styled.div`
  text-align: start;
`

export const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.colors.neutral[5]};
  text-decoration: underline;
`
