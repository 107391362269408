import { styled } from 'styled-components'

import { SpacerLevel } from 'types'

export interface SpacerProps {
  ma?: SpacerLevel
  mt?: SpacerLevel
  mr?: SpacerLevel
  mb?: SpacerLevel
  ml?: SpacerLevel
  pa?: SpacerLevel
  pt?: SpacerLevel
  pr?: SpacerLevel
  pb?: SpacerLevel
  pl?: SpacerLevel
  display?: 'block' | 'flex' | 'inline' | 'inline-flex'
  children?: never
}

export const Spacer = styled.span<SpacerProps>`
  display: ${({ display }) => display || 'flex'};
  margin: ${({ ma, theme }) => ma && theme.spacers[ma]};
  margin-top: ${({ mt, theme }) => mt && theme.spacers[mt]};
  margin-right: ${({ mr, theme }) => mr && theme.spacers[mr]};
  margin-bottom: ${({ mb, theme }) => mb && theme.spacers[mb]};
  margin-left: ${({ ml, theme }) => ml && theme.spacers[ml]};
  padding: ${({ pa, theme }) => pa && theme.spacers[pa]};
  padding-top: ${({ pt, theme }) => pt && theme.spacers[pt]};
  padding-right: ${({ pr, theme }) => pr && theme.spacers[pr]};
  padding-bottom: ${({ pb, theme }) => pb && theme.spacers[pb]};
  padding-left: ${({ pl, theme }) => pl && theme.spacers[pl]};
`
