import { styled } from 'styled-components'

import { Container } from 'designSystem'

export const Wrapper = styled(Container)`
  &&&&&&& .ant-btn {
    border: 1px solid transparent;
    color: ${({ theme }) => theme.colors.neutral[10]};
    svg {
      fill: ${({ theme }) => theme.colors.neutral[10]};
    }
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.neutral[10]};
    }
  }

  a:hover {
    span {
      text-decoration: none;
    }
  }
`
