import { FC, forwardRef, Ref, useCallback, useState } from 'react'
import ReactQuill from 'react-quill'

import { modules } from './constants/modules'
import * as Styled from './styles'

import 'react-quill/dist/quill.snow.css'

interface EditorProps {
  onChange?: (value: string) => void
  value?: string
  height?: number
  readOnly?: boolean
}

export const Editor: FC<EditorProps> = forwardRef(
  ({ readOnly = false, onChange, value, height }, ref: Ref<ReactQuill>) => {
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = useCallback(() => {
      setIsFocused(true)
    }, [])

    const handleBlur = useCallback(() => {
      setIsFocused(false)
    }, [])

    const props = readOnly
      ? { readOnly: true, theme: 'bubble' }
      : { onChange, onFocus: handleFocus, onBlur: handleBlur }

    return (
      <Styled.EditorWrapper $readOnly={readOnly} $height={height} $isFocused={isFocused}>
        <ReactQuill ref={ref} value={value} modules={modules} {...props} />
      </Styled.EditorWrapper>
    )
  },
)
