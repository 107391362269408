import { ErrorCode } from 'enums'
import { errorMessages } from 'constant'

export const getErrorMessageFromErrorCode = (errorCode: ErrorCode | undefined): string | undefined => {
  const foundErrorCode = Object.values(ErrorCode).find((key) => key === errorCode)
  if (foundErrorCode) {
    return errorMessages[foundErrorCode]
  }

  return undefined
}
