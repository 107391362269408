import { FC } from 'react'
import { Link } from 'react-router-dom'

import { useGetBreadcrumbs } from 'store'

import { Container, Typography } from 'designSystem'

export const Breadcrumbs: FC = () => {
  const breadcrumbs = useGetBreadcrumbs()

  if (!breadcrumbs) return null

  return (
    <Container>
      {breadcrumbs.map((breadcrumb, index) => {
        const lastEl = index === breadcrumbs.length - 1
        return (
          <Container key={index} ai="center">
            {index !== 0 && (
              <Container mx={2}>
                <Typography>/</Typography>
              </Container>
            )}
            {lastEl ? (
              <Typography nowrap color="white">
                {breadcrumb.name || 'Loading...'}
              </Typography>
            ) : (
              <Link to={breadcrumb.link}>
                <Typography nowrap color="secondary">
                  {breadcrumb.name || 'Loading...'}
                </Typography>
              </Link>
            )}
          </Container>
        )
      })}
    </Container>
  )
}
