import { HealthConsideration } from 'generated'

export const healthConsiderationsOptions = [
  {
    value: HealthConsideration.HighBloodPressure,
    label: 'High Blood Pressure',
  },
  {
    value: HealthConsideration.LowBloodPressure,
    label: 'Low Blood Pressure',
  },
  {
    value: HealthConsideration.HeartDisease,
    label: 'Heart Disease',
  },
  {
    value: HealthConsideration.BoneOrJointDisease,
    label: 'Bone or Joint Disease',
  },
  {
    value: HealthConsideration.Diabetes,
    label: 'Diabetes',
  },
  {
    value: HealthConsideration.Overweight,
    label: 'Overweight',
  },
  {
    value: HealthConsideration.Pregnant,
    label: 'Pregnant',
  },
]

export const getHealthConsiderationLabel = (value: HealthConsideration) => {
  return healthConsiderationsOptions.find((option) => option.value === value)?.label
}
