import { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { GET_EXERCISE } from 'api/exercise/queries'
import { useChangeBreadcrumbs, useForm } from 'hooks'
import { route } from 'constant'

import { Button, Container, Loader, showNotification } from 'designSystem'
import { ExerciseValues, updateValidationSchema } from 'modules/exercises/utils/validationSchema'

import { getBreadcrumbs } from './utils/getBreadCrumbs'
import { ExerciseFields } from 'modules/exercises/elements/ExerciseFields'

import { useGetExerciseQuery, useUpdateExerciseMutation } from 'generated'

export const ExerciseEdit: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { data, loading } = useGetExerciseQuery({ variables: { id: String(id) }, skip: !id })
  const [update, { loading: isUpdating }] = useUpdateExerciseMutation({
    onCompleted: () => {
      showNotification({ title: 'Exercise updated', type: 'success' })
      navigate(`${route.exercises}/${id}`)
    },
    update: (cache, { data }) => {
      cache.updateQuery({ query: GET_EXERCISE, variables: { id: String(id) } }, () => {
        return { getExercise: data?.updateExercise }
      })
    },
  })
  useChangeBreadcrumbs(getBreadcrumbs(data?.getExercise.name, id))

  const { Form, handleSubmit } = useForm<ExerciseValues>({
    validationSchema: updateValidationSchema,
    defaultValues: {
      name: data?.getExercise.name,
      description: data?.getExercise.description,
      muscles: data?.getExercise.muscles || [],
      equipments: data?.getExercise.equipments || [],
      healthConsiderations: data?.getExercise.healthConsiderations || [],
      restTime: data?.getExercise.restTime,
      bothSides: data?.getExercise.bothSides,
      isPublic: data?.getExercise.isPublic,
    },
  })

  const onSubmit = useCallback(
    (values: ExerciseValues) => {
      update({
        variables: {
          updateExerciseInput: {
            id: String(id),
            name: values.name,
            description: values.description,
            muscles: values.muscles,
            equipments: values.equipments,
            healthConsiderations: values.healthConsiderations,
            restTime: values.restTime,
            bothSides: values.bothSides,
            isPublic: values.isPublic,
          },
        },
      })
    },
    [update, id],
  )

  const handleCancel = useCallback(() => {
    navigate(`${route.exercises}/${id}`)
  }, [navigate, id])

  if (loading) {
    return <Loader />
  }

  return (
    <Form>
      <ExerciseFields
        actions={
          <Container gap={16}>
            <Button loading={isUpdating} size="small" type="primary" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
          </Container>
        }
      />
    </Form>
  )
}
