import { css } from 'styled-components'

export const CropperStyles = css`
  &&& .img-crop-modal {
    .img-crop-control {
      width: 100%;
    }

    .ant-slider-track {
      background-color: ${({ theme }) => theme.colors.primary.main};
    }
    .ant-slider-handle {
      &:after {
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary.main};
      }
    }

    &&& .reactEasyCrop_Container {
      background: ${({ theme }) => theme.colors.neutral[50]};
    }
    .ant-modal-content {
      background: ${({ theme }) => theme.colors.neutral[0]};
    }
    & .ant-modal-footer {
      & .ant-btn {
        height: 40px;
        display: inline-flex;
        align-items: center;
      }
      & .ant-btn-default {
        background: transparent;
        border: none;
        border: 1px solid transparent;
        &:hover {
          border: 1px solid ${({ theme }) => theme.colors.neutral[50]};
          color: ${({ theme }) => theme.colors.neutral[50]};
        }
      }
      & .ant-btn-primary {
        background: ${({ theme }) => theme.colors.primary.main};
        color: ${({ theme }) => theme.colors.neutral[50]};

        &:hover {
          background: ${({ theme }) => theme.colors.neutral[50]};
          color: ${({ theme }) => theme.colors.neutral[0]};
          box-shadow: none;
          border: 1px solid ${({ theme }) => theme.colors.neutral[50]};
        }
      }
    }
  }
`
