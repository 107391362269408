import { css, styled } from 'styled-components'

import { TagColorVariant } from './types/colorVariant'

export const Wrapper = styled.div<{ $color: TagColorVariant }>`
  border: 1px solid transparent;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: ${({ theme }) => theme.fontSize.l};
  ${({ $color }) => {
    switch ($color) {
      case 'blue':
        return css`
          background: ${({ theme }) => theme.colors.blue[100]};
          border-color: ${({ theme }) => theme.colors.blue[80]};
        `
      case 'green':
        return css`
          background: ${({ theme }) => theme.colors.green[100]};
          border-color: ${({ theme }) => theme.colors.green[80]};
        `
      case 'brown':
        return css`
          background: ${({ theme }) => theme.colors.orange[100]};
          border-color: ${({ theme }) => theme.colors.orange[80]};
        `
      default:
        return css`
          background: ${({ theme }) => theme.colors.blue[100]};
          border-color: ${({ theme }) => theme.colors.blue[80]};
        `
    }
  }}
`
