import { FC } from 'react'

import { useUser } from 'store'

import { Container } from 'designSystem'
import { Avatar } from 'components/Avatar'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Notifications } from 'components/Notifications'

import { Search } from './Search'

export const Header: FC = () => {
  const user = useUser()

  return (
    <Container ai="center" jc="space-between" width="100%">
      <Breadcrumbs />
      <Container gap="16" ai="center" jc="flex-end" width="100%">
        <Search />
        <Notifications />
        <Avatar user={user} shape="square" size="large" />
      </Container>
    </Container>
  )
}
