import { FC } from 'react'

import { useGetSearchParams } from 'hooks'

import { Container, Loader, Pagination, Spacer, Typography } from 'designSystem'

import { useGetExercises } from './hooks/useGetExercises'

import { SingleExercise } from '../SingleExercise'

export const List: FC = () => {
  const { data, loading } = useGetExercises()
  const { equipment, healthConsideration, muscle } = useGetSearchParams()

  const isFiltered = Boolean(equipment || healthConsideration || muscle)

  return (
    <Container pa={4} br="l" bg="neutral.60" gap={12} fd="column" width="100%">
      {!data?.getAllExercises.data.length ? (
        <>
          {loading ? (
            <Container bg="neutral.60" width="100%" height="100%">
              <Loader />
            </Container>
          ) : (
            <Container height="100%" width="100%" jc="center" fd="column" ai="center">
              <img src="/yoga-mat.png" width={242} />
              <Container mt={9} fd="column" ai="center" jc="center" maxWidth={400}>
                {isFiltered ? (
                  <Typography color="white" size="h4">
                    No exercises found
                  </Typography>
                ) : (
                  <>
                    <Typography color="white" size="h4">
                      Select Category
                    </Typography>
                    <Spacer mb={3} />
                    <Typography align="center" color="white" size="l">
                      Select one of the categories to view existing exercises or click “Add exercise” to create your
                      own.
                    </Typography>
                  </>
                )}
              </Container>
            </Container>
          )}
        </>
      ) : (
        <>{data?.getAllExercises.data.map((exercise) => <SingleExercise key={exercise.name} {...exercise} />)}</>
      )}
      <Pagination total={data?.getAllExercises.total || 0} />
    </Container>
  )
}
