import { FC } from 'react'

import { useChangeBreadcrumbs } from 'hooks'

import { Typography } from 'designSystem'

import { breadcrumbs } from './constants/breadcrumbs'

export const Dashboard: FC = () => {
  useChangeBreadcrumbs(breadcrumbs)
  return (
    <>
      <Typography size="h1" color="white">
        Dashboard
      </Typography>
    </>
  )
}
