import { FC } from 'react'

import { envVariables } from 'constant'

import { GoogleButton } from './elements/GoogleButton/GoogleButton'

import { GoogleOAuthProvider } from '@react-oauth/google'

const clientId = envVariables.googleClientId

export const GoogleAuth: FC = () => {
  if (!clientId) return
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <GoogleButton />
    </GoogleOAuthProvider>
  )
}
