import { Menu as BaseMenu } from 'antd'
import { styled } from 'styled-components'

export const Menu = styled(BaseMenu)<any>`
  &&&.ant-menu {
    background: transparent;
    width: 300px;

    .ant-menu-sub {
      max-height: 200px;
      overflow-x: auto;
      margin-right: 22px;
    }
  }

  &&& .ant-menu-title-content a,
  &&& .ant-menu-submenu-arrow {
    color: ${({ theme }) => theme.colors.neutral[5]};
  }

  &&& .ant-menu-item-icon {
    fill: ${({ theme }) => theme.colors.neutral[5]};
  }

  &&& .ant-menu-submenu {
    margin-left: 0;
    padding: 28px 0;
  }

  & .ant-menu-submenu-title {
    padding: 0 !important;
  }

  &&& .ant-menu-submenu-selected {
    svg {
      fill: ${({ theme }) => theme.colors.primary.blue[60]};
    }
    & .ant-menu-submenu-arrow {
      color: ${({ theme }) => theme.colors.primary.blue[60]};
    }
  }

  &&& .ant-menu-submenu-selected,
  &&& .ant-menu-submenu-title:active {
    background: ${({ theme }) => theme.colors.neutral[50]};
    & .ant-menu-submenu-title a:first-of-type {
      color: ${({ theme }) => theme.colors.primary.blue[60]};
    }
  }

  &&& .ant-menu-submenu:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[30]};
    border-radius: 0;
  }
  &&& .ant-menu-item-only-child {
    padding-left: 30px !important;
  }

  &&& .ant-menu-sub.ant-menu-inline {
    background: transparent;
  }

  &&& a {
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  &&& .ant-menu-item-selected,
  &&& .ant-menu-item:active,
  &&& .ant-menu-item:hover {
    background: transparent;
    background-color: transparent;
    a {
      color: ${({ theme }) => theme.colors.primary.blue[60]};
    }
  }

  &&& .ant-menu-submenu-title:hover {
    background-color: transparent;
  }
`
