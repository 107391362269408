import { ErrorCode } from 'enums'
import { ErrorMessage } from 'types'

export const errorMessages: Partial<ErrorMessage> = {
  [ErrorCode.UserNotFound]: 'User does not exist',
  [ErrorCode.PasswordIsNotCorrect]: 'Password is not correct',
  [ErrorCode.UserNameAlreadyExists]: 'Username already exists',
  [ErrorCode.TokenExpired]: 'Token expired',
  [ErrorCode.EmailNotVerified]: 'Email not verified',
  [ErrorCode.EmailAlreadyExists]: 'Email already exists',
  [ErrorCode.EmailAlreadyVerified]: 'Email already verified',
}
