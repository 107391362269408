import { apolloClient } from 'config'
import { AppEvent, QueryKey } from 'enums'

const getRelatedQueryKeysByQueryEntity = (appEvent: AppEvent): QueryKey[] | undefined => {
  switch (appEvent) {
    case AppEvent.ExerciseCreate:
    case AppEvent.DeleteExercise:
      return [QueryKey.GetAllExercises]
    case AppEvent.UpdateExercise:
      return [QueryKey.GetAllExercises, QueryKey.GetExercise]
    default:
      return undefined
  }
}

export const invalidateRelatedQueries = (appEvent: AppEvent) => {
  getRelatedQueryKeysByQueryEntity(appEvent)?.forEach((queryKey) => apolloClient.cache.evict({ fieldName: queryKey }))

  // Call garbage collection to clean up
  apolloClient.cache.gc()
}
