import { Link } from 'react-router-dom'

import { ExercisesType } from 'enums'
import { getMuscleOptionLabel, route } from 'constant'

import { Muscle } from 'generated'

export const muscleGroupItems = () => {
  return Object.values(Muscle).map((muscle) => ({
    label: <Link to={`${route.exercises}?${ExercisesType.Muscle}=${muscle}`}>{getMuscleOptionLabel(muscle)}</Link>,
    key: muscle,
  }))
}
