import { FC } from 'react'
import { Spin } from 'antd'

import { Container } from 'designSystem/Container'

export const Loader: FC = () => {
  return (
    <Container height="100%" ai="center" width="100%" jc="center">
      <Spin spinning size="large" />
    </Container>
  )
}
