import { notification as baseNotification } from 'antd'
import { NotificationPlacement } from 'antd/lib/notification/interface'

import { Container } from 'designSystem/Container'

import { NotificationType } from '../types'
import * as Styled from '../styles'

import { getIconByType } from './getIconByType'

export interface NotificationProps {
  duration?: number
  type?: NotificationType
  title: string
  message?: string
  onOk?: () => void
  placement?: NotificationPlacement
}

export const showNotification = ({
  duration = 3,
  placement,
  type = 'default',
  title,
  message,
  onOk,
}: NotificationProps) => {
  const key = crypto.randomUUID()

  const handleClose = () => {
    baseNotification.destroy(key)
  }

  const handleConfirm = () => {
    if (onOk) {
      onOk()
    }
    handleClose()
  }

  baseNotification.open({
    key,
    placement: placement || 'topRight',
    className: `${type} ${duration ? 'with-duration' : ''} ${!message ? 'title-only' : ''}`,
    message: title,
    icon: getIconByType(type),
    duration,
    description: message,
    btn: onOk && (
      <Container gap={16}>
        <Styled.CancelButton onClick={handleClose}>Cancel</Styled.CancelButton>
        <Styled.ConfirmButton onClick={handleConfirm}>Confirm</Styled.ConfirmButton>
      </Container>
    ),
  })
}
