import { Equipment } from 'generated'

export const equipmentOptions = [
  { value: Equipment.Dumbbell, label: 'Dumbbell' },
  { value: Equipment.Barbell, label: 'Barbell' },
  { value: Equipment.Bodyweight, label: 'Bodyweight' },
  { value: Equipment.Machine, label: 'Machine' },
  { value: Equipment.Cable, label: 'Cable' },
  { value: Equipment.Bands, label: 'Bands' },
]

export const getEquipmentOptionsLabel = (value: Equipment) => {
  return equipmentOptions.find((option) => option.value === value)?.label
}
