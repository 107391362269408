import { css, keyframes, styled } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

interface IconWrapperProps {
  $clickable?: boolean
  spin?: boolean
  hoverColor?: string
  color?: string
}

export const IconWrapper = styled.span<IconWrapperProps>`
  display: inline-flex;
  ${({ spin }) =>
    spin &&
    css`
      animation: ${rotate} 1s linear infinite;
    `}

  cursor: ${({ $clickable }) => $clickable && 'pointer'};
  ${({ color }) =>
    color &&
    css`
      svg {
        fill: ${color};
        transition: fill 0.2s ease-in-out;
      }
    `}

  ${({ hoverColor }) =>
    hoverColor &&
    css`
      &:hover {
        svg {
          fill: ${hoverColor};
        }
      }
    `}
`
