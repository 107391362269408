import { Checkbox as CheckboxBase } from 'antd'
import { css, styled } from 'styled-components'

import type { CheckboxProps } from './Checkbox'

export const BaseStyle = css<CheckboxProps>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[0]};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  .ant-checkbox {
    top: unset;
  }
`

export const Checkbox = styled(CheckboxBase)<CheckboxProps>`
  &&&&.ant-checkbox-wrapper {
    ${BaseStyle}
  }
  &&& .ant-checkbox-input {
    margin: 0;
  }
  &&& .ant-checkbox-inner {
    background: transparent;
    border: 2px solid ${({ theme }) => theme.colors.neutral[20]};
    width: 20px;
    height: 20px;
    &:after {
      top: 45%;
      width: 7px;
      height: 11px;
      border-color: ${({ theme }) => theme.colors.neutral[50]};
    }
  }

  ${({ error }) =>
    error &&
    css`
      &&&& .ant-checkbox-inner {
        border-width: 1px;
        border-color: ${({ theme }) => theme.colors.red[60]};
      }
    `}

  &&&&&.ant-checkbox-wrapper-checked {
    &&&& .ant-checkbox-inner {
      background: ${({ theme }) => theme.colors.primary.main};
      border-color: ${({ theme }) => theme.colors.primary.main};
    }
    &&&&:hover .ant-checkbox-inner {
      background: ${({ theme }) => theme.colors.neutral[0]};
      border-color: ${({ theme }) => theme.colors.neutral[0]};
    }
  }

  &&&:hover .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.neutral[0]};
  }

  &&&.ant-checkbox-wrapper-disabled {
    span {
      color: ${({ theme }) => theme.colors.neutral[0]};
    }

    &&&&.ant-checkbox-inner,
    &&&&:hover .ant-checkbox-inner {
      background: ${({ theme }) => theme.colors.neutral[30]};
      border-color: ${({ theme }) => theme.colors.neutral[20]};
    }
  }
`
