import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useChangeBreadcrumbs, useForm } from 'hooks'
import { route } from 'constant'

import { Button, Container, Spacer, Typography } from 'designSystem'

import { useCreateExercise } from './hooks/useCreateExercise'
import { createValidationSchema, ExerciseValues } from '../../utils/validationSchema'
import { breadcrumbs } from './constants/breadcrumbs'
import { ExerciseFields } from '../../elements/ExerciseFields'

export const ExerciseCreate: FC = () => {
  const navigate = useNavigate()

  const { loading, create } = useCreateExercise()

  const { Form, handleSubmit } = useForm<ExerciseValues>({
    validationSchema: createValidationSchema,
    shouldFocusError: false,
  })

  const onSubmit = useCallback(
    (values: ExerciseValues) => {
      create(values)
    },
    [create],
  )

  const handleCancel = useCallback(() => {
    navigate(route.exercises)
  }, [navigate])

  useChangeBreadcrumbs(breadcrumbs)

  return (
    <Container fd="column" width="100%">
      <Typography color="white" size="h4">
        Add Exercises
      </Typography>
      <Spacer mb={4} />
      <Form>
        <ExerciseFields
          actions={
            <Container gap={16}>
              <Button size="small" type="primary" onClick={handleSubmit(onSubmit)} loading={loading}>
                Add
              </Button>
              <Button size="small" onClick={handleCancel}>
                Cancel
              </Button>
            </Container>
          }
        />
      </Form>
    </Container>
  )
}
