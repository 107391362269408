import { forwardRef, ReactElement, Ref, useCallback } from 'react'
import { Tooltip } from 'antd'
import ImgCrop from 'antd-img-crop'

import { Button } from 'designSystem/Button'

import type { UploadFile } from './types/uploadFile'
import * as Styled from './styles'

interface UploadProps {
  onChange?: (file: UploadFile) => void
  error?: string
}

export const Upload = forwardRef(({ onChange, error }: UploadProps, ref: Ref<HTMLInputElement>): ReactElement => {
  const uploadProps = {
    customRequest: ({ onSuccess }: any) => {
      onSuccess('ok')
    },
    maxCount: 1,
    itemRender: (originNode: ReactElement) => {
      if (error) {
        return <Tooltip title={error}>{originNode}</Tooltip>
      } else {
        return originNode
      }
    },
  }

  const handleOk = useCallback(
    (file: any) => {
      onChange?.(file)
    },
    [onChange],
  )

  return (
    <Styled.Wrapper $error={!!error} ref={ref}>
      <ImgCrop
        modalTitle="Crop Image"
        modalWidth={450}
        modalOk="Confirm"
        rotationSlider
        quality={0.8}
        showGrid
        fillColor="black"
        onModalOk={handleOk}
      >
        <Styled.BaseUpload {...uploadProps}>
          <Button size="small" iconRight="arrowUp">
            Upload image
          </Button>
        </Styled.BaseUpload>
      </ImgCrop>
    </Styled.Wrapper>
  )
})
