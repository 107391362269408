import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { ConfigProvider } from 'antd'
import { StyleSheetManager, ThemeProvider } from 'styled-components'

import { apolloClient } from 'config'
import { GlobalStyle } from 'styles/globalStyles'
import theme, { configProviderSetup } from 'styles/theme'

import isPropValid from '@emotion/is-prop-valid'

import { App } from './App'
import reportWebVitals from './reportWebVitals'

declare module 'react' {
  function forwardRef<T, P extends object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StyleSheetManager
    shouldForwardProp={(propName, elementToBeRendered) => {
      return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
    }}
  >
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={configProviderSetup}>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </ConfigProvider>
      <GlobalStyle />
    </ThemeProvider>
  </StyleSheetManager>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
