import { exercisesRoutes } from 'constant'

import { Exercise } from '../routes/Exercise'
import { ExerciseCreate } from '../routes/ExerciseCreate'
import { ExercisesList } from '../routes/ExercisesList'

export const routes = [
  {
    path: exercisesRoutes.list,
    element: <ExercisesList />,
  },
  {
    path: exercisesRoutes.create,
    element: <ExerciseCreate />,
  },
  {
    path: `${exercisesRoutes.view}/*`,
    element: <Exercise />,
  },
  {
    path: '*',
    element: <>Not found</>,
  },
]
