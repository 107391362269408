import { FC, useCallback } from 'react'

import { useChangeBreadcrumbs, useMenuItems } from 'hooks'
import { route } from 'constant'

import { Container, Spacer, Typography } from 'designSystem'
import { CreateBlock } from 'components'

import { breadcrumbs } from './constants/breadcrumbs'
import { exercisesMenuItems } from './constants/exercisesMenuItems'
import { List } from './elements/List'
import * as Styled from './styles'

export const ExercisesList: FC = () => {
  useChangeBreadcrumbs(breadcrumbs)
  const { activeMenuItem, handleClickMenuItem, openKeys, setOpenKeys, setActiveMenuItem } = useMenuItems({
    menuItems: exercisesMenuItems,
  })

  const handleOpenChange = useCallback(
    (keys: string[]) => {
      const lastEl = keys[keys.length - 1]
      setActiveMenuItem(null)
      setOpenKeys([lastEl])
    },
    [setOpenKeys, setActiveMenuItem],
  )

  return (
    <Container gap={30} width="100%">
      <Container fd="column" jc="space-between">
        <Container fd="column">
          <Typography size="h4" color="white">
            Exercises
          </Typography>
          <Spacer mb={9} />
          <Styled.Menu
            onOpenChange={handleOpenChange}
            openKeys={openKeys}
            selectedKeys={activeMenuItem ? [activeMenuItem] : []}
            items={exercisesMenuItems}
            onClick={handleClickMenuItem}
            mode="inline"
          />
        </Container>
        <CreateBlock
          title="Need a different exercises?"
          text="Customize your workout with any exercise!"
          buttonText="Add exercise"
          link={route.exerciseCreate}
        />
      </Container>
      <List />
    </Container>
  )
}
