import { css } from 'styled-components'

export const NotificationStyles = css`
  &&&&&&&&&&&.ant-notification {
    z-index: ${({ theme }) => theme.zIndexLevel.notifications};

    .ant-notification-notice {
      background: ${({ theme }) => theme.colors.neutral[0]};
      border-radius: ${({ theme }) => theme.borderRadius.m};
      padding: ${({ theme }) => theme.spacers[7]};

      .ant-notification-notice-message {
        color: ${({ theme }) => theme.colors.neutral[50]};
        font-weight: ${({ theme }) => theme.fontWeight.bold};
        margin-inline-start: 0;
        margin-bottom: ${({ theme }) => theme.spacers[0]} !important;
      }
    }
  }
`
