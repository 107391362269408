import { exercisesRoot, exercisesRoutes } from './exercisesRoutes'
import { trainingsRoot, trainingsRoutes } from './trainingsRoutes'

export const route = {
  register: '/register',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  terms: '/terms',
  privacy: '/privacy',
  home: '/',
  dashboard: '/dashboard',
  profile: '/profile',
  //exercises
  exercises: exercisesRoot,
  exercise: exercisesRoutes.view,
  exerciseCreate: `${exercisesRoot}/${exercisesRoutes.create}`,
  exercisesList: `${exercisesRoot}/${exercisesRoutes.list}`,

  //trainings
  trainings: trainingsRoot,
  trainingCreate: `${trainingsRoot}/${trainingsRoutes.create}`,
  trainingsList: `${trainingsRoot}/${trainingsRoutes.list}`,
}
