import { FC, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import type { PaginationProps as BasePaginationProps } from 'antd/lib'

import { pageLimit } from 'constant'
import { typedMemo } from 'types'
import { useGetPage, useSetPage } from 'store'

import { Container } from 'designSystem/Container'

import * as Styled from './styles'

type PaginationProps = BasePaginationProps

export const Pagination: FC<PaginationProps> = typedMemo((props) => {
  const location = useLocation()
  const currentPage = useGetPage()
  const setPage = useSetPage()

  const handleChange = useCallback(
    (page: number) => {
      setPage(page)
    },
    [setPage],
  )

  useEffect(() => {
    return () => {
      setPage(1)
    }
  }, [setPage])

  useEffect(() => {
    setPage(1)
  }, [location.search, location.pathname, setPage])

  return (
    <Container jc="flex-end" flex="1" ai="flex-end">
      <Styled.PaginationItem
        {...props}
        current={currentPage}
        showSizeChanger={false}
        hideOnSinglePage
        onChange={handleChange}
        defaultPageSize={pageLimit}
      />
    </Container>
  )
})
