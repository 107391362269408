import { passwordRegExp } from 'constant'

import type { UploadFile } from 'designSystem'

import { z } from 'zod'

export const maxPasswordLength = 64
export const minPasswordLength = 8

export const maxFileSizeMB = 10

export const passwordValidation = z
  .string()
  .min(minPasswordLength, 'Password is required')
  .max(maxPasswordLength)
  .regex(passwordRegExp)

export const fileValidation = z
  .custom<UploadFile>()
  .refine((file) => file, {
    message: 'File is required',
  })
  .refine(
    (file) => {
      if (!file) return true
      const maxFileSize = maxFileSizeMB * 1024 * 1024
      const isAcceptedFileSize = ((file.size as number) || 0) < maxFileSize

      return isAcceptedFileSize
    },
    {
      message: `File must be less than ${maxFileSizeMB}MB in size`,
    },
  )
