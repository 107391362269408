import { trainingsRoutes } from 'constant'

import { TrainingCreate } from '../routes/TrainingCreate'
import { TrainingsList } from '../routes/TrainingsList'

export const routes = [
  {
    path: trainingsRoutes.list,
    element: <TrainingsList />,
  },
  {
    path: trainingsRoutes.create,
    element: <TrainingCreate />,
  },
  // {
  //   path: `${trainingsRoutes.view}/*`,
  //   element: <Training />,
  // },
  {
    path: '*',
    element: <>Not found</>,
  },
]
