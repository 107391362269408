import { FC, useCallback } from 'react'

import { Button, showNotification } from 'designSystem'

import * as Styled from './styles'

import { useToggleFavoriteMutation } from 'generated'

interface LikeExerciseProps {
  id: string
  isFavorite: boolean
}
export const LikeExercise: FC<LikeExerciseProps> = ({ id, isFavorite }) => {
  const [toggleFavorite, { loading }] = useToggleFavoriteMutation({
    variables: { id },
    onCompleted: () => {
      showNotification({ title: isFavorite ? 'Liked' : 'Disliked', type: 'success' })
    },
    update: (cache, { data }) => {
      cache.modify({
        id: `${data?.toggleFavorite.__typename}:${id}`,
        fields: {
          isFavorite(existingIsFavorite = false) {
            return data?.toggleFavorite.isFavorite ?? existingIsFavorite
          },
        },
      })
    },
  })
  const toggleLike = useCallback(() => {
    toggleFavorite()
  }, [toggleFavorite])

  return (
    <Styled.ButtonWrapper $isFavorite={isFavorite}>
      <Button loading={loading} onClick={toggleLike} block iconLeft="heart">
        {isFavorite ? 'Liked' : 'Like'}
      </Button>
    </Styled.ButtonWrapper>
  )
}
