import { Layout as BaseLayout, Menu as BaseMenu } from 'antd'
import { styled } from 'styled-components'

export const Sidebar = styled(BaseLayout.Sider)`
  padding: ${({ theme }) => `${theme.spacers[3]}`};
  &.ant-layout-sider {
    background: ${({ theme }) => theme.colors.neutral[50]};
    border-radius: ${({ theme }) => theme.borderRadius.xl};
    padding: ${({ theme }) => theme.spacers[9]} 12px;
  }
  margin-right: ${({ theme }) => theme.spacers[7]};
`

export const Menu = styled(BaseMenu)<any>`
  &&.ant-menu {
    background: ${({ theme }) => theme.colors.neutral[50]};
    border-inline-end: unset;
    &&&& .ant-menu-item-selected {
      background: ${({ theme }) => theme.colors.primary.blue[80]};
      color: ${({ theme }) => theme.colors.primary.blue[10]};
      svg {
        fill: ${({ theme }) => theme.colors.primary.blue[10]};
      }
    }
    &&& .ant-menu-item {
      color: ${({ theme }) => theme.colors.neutral[10]};
      font-size: ${({ theme }) => theme.fontSize.xl};
      line-height: ${({ theme }) => theme.lineHeight.xl};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      margin-inline: 0;
      display: flex;
      align-items: center;
      padding: ${({ theme }) => `0 ${theme.spacers[7]}`};
      margin: ${({ theme }) => `${theme.spacers[3]} 0`};
      svg {
        fill: ${({ theme }) => theme.colors.neutral[10]};
      }
      &:active {
        background: ${({ theme }) => theme.colors.primary.blue[80]};
      }

      .ant-menu-title-content {
        white-space: normal;
      }
    }
  }
`
