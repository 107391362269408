import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppEvent } from 'enums'
import { exerciseRoutes, route } from 'constant'
import { invalidateRelatedQueries } from 'utils/invalidateRelatedQueries'

import { Button, Container, showNotification } from 'designSystem'

import { useRemoveExerciseMutation } from 'generated'

interface ActionsProps {
  id: string
}

export const Actions: FC<ActionsProps> = ({ id }) => {
  const navigate = useNavigate()
  const [deleteExercise, { loading }] = useRemoveExerciseMutation()

  const handleDelete = useCallback(() => {
    deleteExercise({ variables: { id } })
    showNotification({ title: 'Exercise deleted', type: 'success' })
    invalidateRelatedQueries(AppEvent.DeleteExercise)
    navigate(route.exercises)
  }, [id, deleteExercise, navigate])

  const handleEdit = useCallback(() => {
    navigate(`${route.exercises}/${id}${exerciseRoutes.edit}`)
  }, [navigate, id])

  return (
    <Container jc="space-between" gap={8}>
      <Button block type="text" iconLeft="pencil" onClick={handleEdit}>
        Edit
      </Button>
      <Button loading={loading} block type="text" iconLeft="crossCircle" onClick={handleDelete}>
        Delete
      </Button>
    </Container>
  )
}
