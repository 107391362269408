import { FC, forwardRef, Ref, useCallback } from 'react'
import { InputNumberProps as BaseInputNumberProps } from 'antd/lib/input-number'

import { numberFormatter } from 'utils'

import { InputNumberValue } from './types'
import * as Styled from './styles'

export interface InputNumberProps extends Omit<BaseInputNumberProps, 'value' | 'onChange'> {
  error?: string
  value?: InputNumberValue
  onChange?: (value: InputNumberValue) => void
  positive?: boolean
  width?: number
}

export const InputNumber: FC<InputNumberProps> = forwardRef(
  (
    { error, positive = true, onChange, width, precision = 0, formatter = numberFormatter, ...props },
    ref: Ref<HTMLInputElement>,
  ) => {
    const handleChange = useCallback(
      (value?: string | number | null) => {
        if (typeof value !== 'string') {
          if (positive && value) {
            onChange?.(Math.abs(value))
          } else {
            onChange?.(value)
          }
        }
      },
      [onChange, positive],
    )
    return (
      <Styled.Wrapper $width={width}>
        <Styled.InputNumber
          {...props}
          ref={ref}
          formatter={formatter}
          onChange={handleChange}
          precision={precision}
          status={error ? 'error' : undefined}
          controls={false}
        />
      </Styled.Wrapper>
    )
  },
)
