import { css, styled } from 'styled-components'

export const ButtonWrapper = styled.span<{
  $isFavorite: boolean
}>`
  ${({ $isFavorite }) =>
    $isFavorite &&
    css`
      &&&&&&&& .ant-btn {
        color: ${({ theme }) => theme.colors.orange[10]};
        border-color: ${({ theme }) => theme.colors.orange[10]};
        svg {
          transition: fill 0.3s;
          fill: ${({ theme }) => theme.colors.orange[10]};
        }
        &:hover {
          color: ${({ theme }) => theme.colors.red[10]};
          border-color: ${({ theme }) => theme.colors.red[10]};
          svg {
            fill: ${({ theme }) => theme.colors.red[10]};
          }
        }
      }
    `}
`
