import { CSSProperties } from 'react'
import { css, styled } from 'styled-components'

import { DeepObjectKeys, SpacerLevel } from 'types'
import { getObjectValueByKey } from 'utils'
import theme from 'styles/theme'

import { Spacer } from '../Spacer'

interface ContainerProps {
  px?: SpacerLevel
  py?: SpacerLevel
  mx?: SpacerLevel
  my?: SpacerLevel
  fd?: CSSProperties['flexDirection']
  jc?: CSSProperties['justifyContent']
  ai?: CSSProperties['alignItems']
  fg?: CSSProperties['flexGrow']
  fw?: CSSProperties['flexWrap']
  gap?: CSSProperties['gap']
  flex?: CSSProperties['flex']
  position?: CSSProperties['position']
  maxWidth?: number | `${number}%`
  width?: number | '100%'
  minHeight?: number | '100%' | '100vh'
  maxHeight?: number
  height?: number | '100%'
  nowrap?: boolean
  flexWrap?: CSSProperties['flexWrap']
  bg?: DeepObjectKeys<typeof theme.colors>
  br?: keyof typeof theme.borderRadius
}

export const Container = styled(Spacer).attrs({ as: 'div' })<ContainerProps>`
  flex-wrap: ${({ flexWrap }) => flexWrap || 'inherit'};
  position: ${({ position }) => position || 'relative'};
  margin: ${({ ma, mx, my, theme }) => {
    if (ma) {
      return theme.spacers[ma]
    }
    if (mx || my) {
      return `${my ? theme.spacers[my] : 0} ${mx ? theme.spacers[mx] : 0}`
    }
  }};

  padding: ${({ pa, px, py, theme }) => {
    if (pa) {
      return theme.spacers[pa]
    }
    if (px || py) {
      return `${py ? theme.spacers[py] : 0} ${px ? theme.spacers[px] : 0}`
    }
  }};
  ${({ fd }) => fd && `flex-direction: ${fd};`}
  ${({ jc }) => jc && `justify-content: ${jc};`}
  ${({ ai }) => ai && `align-items: ${ai};`}
  ${({ gap }) => gap && `gap: ${gap}px;`}
  ${({ fg }) => fg && `flex-grow: ${fg};`}
  ${({ fw }) => fw && `flex-wrap: ${fw};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${typeof maxWidth !== 'number' ? `${maxWidth}` : `${maxWidth}px`};
      width: 100%;
    `}
  width: ${({ width }) => {
    if (width) {
      return typeof width === 'number' ? `${width}px` : width
    }
  }};
  ${({ minHeight }) => ({
    minHeight: typeof minHeight === 'number' ? `${minHeight}px` : minHeight,
  })}
  ${({ height }) => ({
    height: typeof height === 'number' ? `${height}px` : height,
  })}
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
  ${({ nowrap }) => nowrap && `white-space: nowrap;`}
  ${({ bg, theme }) => bg && `background-color: ${getObjectValueByKey(bg, theme.colors)};`}
  ${({ br, theme }) => br && `border-radius: ${getObjectValueByKey(br, theme.borderRadius)};`}
`
