import { styled } from 'styled-components'

import { Container } from 'designSystem/Container'

export const Wrapper = styled.div<{
  $width?: number
}>`
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
  .ant-select {
    width: 100%;
  }

  &&& .ant-select-dropdown {
    background: green !important;
  }

  &&& .ant-select-selection-item {
    color: ${({ theme }) => theme.colors.neutral[5]};
    background: ${({ theme }) => theme.colors.neutral[30]};
    .ant-checkbox-wrapper {
      display: none;
    }
    .ant-select-selection-item-remove {
      color: ${({ theme }) => theme.colors.neutral[5]};
    }
  }

  &&&&& .ant-select-focused .ant-select-selector {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }

  &&&& .ant-select-arrow {
    color: ${({ theme }) => theme.colors.neutral[5]};
  }

  &&&& .ant-select-arrow {
    fill: ${({ theme }) => theme.colors.neutral[5]};
  }
`

export const DropdownMenu = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[30]} !important;
  padding: 2px;

  .ant-select-dropdown {
    background-color: ${({ theme }) => theme.colors.neutral[30]} !important;
  }

  &&& .ant-select-item {
    border-radius: 6px;
    padding: 0;
  }

  &&& .ant-select-item-option-selected {
    background: ${({ theme }) => theme.colors.neutral[30]};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  &&& .ant-select-item-option-content {
    color: ${({ theme }) => theme.colors.neutral[5]};
  }

  &&& .ant-select-item-option-state {
    display: none;
  }
`
export const Item = styled(Container)`
  padding: 6px 12px;
  &:hover {
    background: ${({ theme }) => theme.colors.neutral[50]};
  }
`
