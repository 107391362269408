import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { route } from 'constant'

import { Button, Container } from 'designSystem'

export const TrainingsList: FC = () => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(route.trainingCreate)
  }, [navigate])

  return (
    <Container>
      <Button iconLeft="addCircle" onClick={handleClick}>
        Create workout program
      </Button>
    </Container>
  )
}
