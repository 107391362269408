import { useCallback } from 'react'

import { showNotification } from 'designSystem'

import { useForgotPasswordMutation } from 'generated'

interface useResendResetPasswordProps {
  email: string
}

export const useResendResetPassword = ({ email }: useResendResetPasswordProps) => {
  const [resendResetPassword, { loading }] = useForgotPasswordMutation({
    onCompleted: () => {
      showNotification({ title: 'Email send', type: 'success' })
    },
  })

  const handleResendResetPassword = useCallback(() => {
    resendResetPassword({
      variables: {
        forgotPasswordInput: { email },
      },
    })
  }, [resendResetPassword, email])

  return { resendResetPassword: handleResendResetPassword, loading }
}
