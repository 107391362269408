import { FC, ReactNode } from 'react'
import { Col, Row } from 'antd'

import { equipmentOptions, healthConsiderationsOptions, muscleOptions } from 'constant'

import {
  Checkbox,
  Container,
  Editor,
  Input,
  InputNumber,
  MultiSelect,
  Spacer,
  Switch,
  Typography,
  Upload,
} from 'designSystem'
import { FormItem } from 'components'

interface ExerciseFieldsProps {
  actions?: ReactNode
}

export const ExerciseFields: FC<ExerciseFieldsProps> = ({ actions }) => {
  return (
    <Row gutter={48}>
      <Col span={8}>
        <FormItem label="Name" name="name">
          <Input placeholder="Name" />
        </FormItem>
        <Spacer mb={4} />
        <FormItem label="Health considerations" name="healthConsiderations">
          <MultiSelect options={healthConsiderationsOptions} />
        </FormItem>
        <Spacer mb={4} />
        <FormItem label="Muscle Groups" name="muscles">
          <MultiSelect options={muscleOptions} />
        </FormItem>
        <Spacer mb={4} />
        <FormItem name="confirmAgreement">
          <Checkbox>Both left and right side</Checkbox>
        </FormItem>
        <Spacer mb={4} />
        <FormItem label="Equipment" name="equipments">
          <MultiSelect options={equipmentOptions} />
        </FormItem>
        <Spacer mb={4} />
        <FormItem label="Rest time" name="restTime">
          <InputNumber placeholder="30 sec" />
        </FormItem>
        <Spacer mb={4} />
        <Container gap={8}>
          <FormItem name="isPublic">
            <Switch />
          </FormItem>
          <Container fd="column">
            <Typography color="white" fontWeight="medium" size="xl">
              Is Public
            </Typography>
            <Typography color="secondary">Other users can see the exercise and add it to their workout</Typography>
          </Container>
        </Container>
        <Spacer mb={4} />
        <FormItem label="Image" name="image">
          <Upload />
        </FormItem>
        {actions && (
          <>
            <Spacer mb={7} />
            {actions}
          </>
        )}
      </Col>
      <Col span={16}>
        <FormItem height="100%" label="Description" name="description">
          <Editor />
        </FormItem>
      </Col>
    </Row>
  )
}
