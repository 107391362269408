import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'

import { getAccessToken } from 'api'
import { ErrorCode } from 'enums'
import { envVariables } from 'constant'
import { getErrorMessageFromErrorCode } from 'utils/getErrorMessageFromErrorCode'

import { showNotification } from 'designSystem'

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const message = getErrorMessageFromErrorCode(error.message as ErrorCode)

      if (message) {
        showNotification({ title: message, type: 'error' })
      }
    })
})

const moduleHttpLink = createUploadLink({
  uri: `${envVariables.apiUrl}/graphql`,
})

const httpLink = new HttpLink({
  uri: `${envVariables.apiUrl}/graphql`,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAccessToken()

  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
        'Apollo-Require-Preflight': 'true',
      },
    })
  }

  return forward(operation)
})

const appLink = from([authMiddleware, errorLink, moduleHttpLink, httpLink])

export const apolloClient = new ApolloClient({
  link: appLink,
  cache: new InMemoryCache(),
})
