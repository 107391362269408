import { useCallback } from 'react'

import { showNotification } from 'designSystem'

import { useResendVerificationMutation } from 'generated'

interface UseResendVerificationProps {
  email: string
}

export const useResendVerification = ({ email }: UseResendVerificationProps) => {
  const [resendVerification, { loading }] = useResendVerificationMutation({
    onCompleted: () => {
      showNotification({ title: 'Email send', type: 'success' })
    },
  })

  const handleResendVerification = useCallback(() => {
    resendVerification({
      variables: {
        resendVerificationEmailInput: {
          email,
        },
      },
    })
  }, [resendVerification, email])

  return {
    resendVerification: handleResendVerification,
    loading,
  }
}
