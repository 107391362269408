import { FC } from 'react'

import { Container, Spacer, Typography } from 'designSystem'
import { UnauthorizedLayout } from 'components'

export const PrivacyPolicy: FC = () => {
  return (
    <UnauthorizedLayout centered={false} maxWidth={620}>
      <Container fd="column">
        <Typography color="white" size="h3">
          Privacy Policy
        </Typography>
        <Spacer mb={4} />
        <Typography color="primary">Last updated: December 05, 2023</Typography>
        <Spacer mb={3} />
        <Typography color="primary">
          This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
          information when You use the Service and tells You about Your privacy rights and how the law protects You.
        </Typography>
        <Spacer mb={3} />
        <Typography color="primary">
          We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
          collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created
          with the help of the Privacy Policy Generator
        </Typography>
        <Spacer mb={4} />
        <Typography color="primary" size="h6">
          Interpretation and Definitions
        </Typography>
        <Spacer mb={3} />
        <Typography color="primary" size="xl">
          Interpretation
        </Typography>
        <Spacer mb={3} />
        <Typography color="primary">
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The
          following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </Typography>
        <Spacer mb={4} />
        <Typography color="primary" size="h6">
          Definitions
        </Typography>
        <Spacer mb={3} />
        <Typography color="primary">For the purposes of this Privacy Policy:</Typography>
        <Spacer mb={4} />
        <Typography color="primary">
          <ul>
            <li>
              <b>Account</b> means a unique account created for You to access our Service or parts of our Service.
            </li>
            <li>
              <b>Affiliate</b> means an entity that controls, is controlled by or is under common control with a party,
              where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other
              securities entitled to vote for election of directors or other managing authority.
            </li>
            <li>
              <b>Application</b> refers to FitMotion, the software program provided by the Company.
            </li>
            <li>
              <b>Company</b> (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to
              FitMotion.
            </li>
            <li>
              <b>Country</b> refers to: Ukraine.
            </li>
            <li>
              <b>Device</b> means any device that can access the Service such as a computer, a cellphone or a digital
              tablet.
            </li>
            <li>
              <b>Personal Data</b> is any information that relates to an identified or identifiable individual.
            </li>
            <li>
              <b>Service</b> refers to the Application.
            </li>
          </ul>
        </Typography>
      </Container>
    </UnauthorizedLayout>
  )
}
