import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { useGetSearchParams } from 'hooks'
import { route } from 'constant'

import { Button, Container, Divider, Icon, Input, Link, Spacer, Typography } from 'designSystem'
import { FormItem, UnauthorizedLayout } from 'components'

import { useSignIn } from './hooks/useSignIn'
import { useVerifyEmail } from './hooks/useVerifyEmail'
import { LoginValues, validationSchema } from './utils/validationSchema'
import { VerifyEmail } from '../elements/VerifyEmail'

import { GoogleAuth } from '../GoogleAuth'

export const Login: FC = () => {
  const [requireVerification, setRequireVerification] = useState(false)
  const form = useForm<LoginValues>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const { token } = useGetSearchParams()

  const { verifyEmail } = useVerifyEmail()

  useEffect(() => {
    if (token) {
      verifyEmail(token as string)
    }
  }, [token, verifyEmail])

  const email = form.watch('email')

  const { signIn, loading } = useSignIn({ setRequireVerification })

  const onSubmit = form.handleSubmit((variables) => {
    signIn(variables)
  })

  if (requireVerification) {
    return <VerifyEmail email={email} />
  }

  return (
    <UnauthorizedLayout>
      <Typography as="h3" size="h3" color="white">
        Welcome back!
      </Typography>
      <Spacer mb={4} />
      <Typography size="l">It is great to have you here, you’ve been missed!</Typography>
      <Container mt={9} fd="column" mb={6}>
        <GoogleAuth />
      </Container>
      <Divider>or</Divider>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Container gap={16} fd="column">
            <FormItem label="Email" name="email">
              <Input placeholder="Email" prefix={<Icon size={16} icon="mail" />} />
            </FormItem>
            <FormItem label="Password" name="password">
              <Input hideChars placeholder="Password" prefix={<Icon size={16} icon="lock" />} />
            </FormItem>
          </Container>
          <Container jc="flex-end" py={7}>
            <Link to={route.forgotPassword}>
              <Typography color="white">Forgot Password?</Typography>
            </Link>
          </Container>
          <Container fd="column">
            <Button htmlType="submit" size="small" type="primary" loading={loading}>
              Sign In
            </Button>
          </Container>
          <Container ai="center" jc="center" mt={9} gap={4}>
            <Typography>Already have an account?</Typography>
            <Link to={route.register}>
              <Typography color="link">Sign Up</Typography>
            </Link>
          </Container>
        </form>
      </FormProvider>
    </UnauthorizedLayout>
  )
}
