import { FC, forwardRef, PropsWithChildren, Ref, useCallback } from 'react'
import { CheckboxChangeEvent, CheckboxProps as BaseCheckboxProps, CheckboxRef } from 'antd/lib/checkbox'

import { typedMemo } from 'types'

import * as Styled from './styles'

export interface CheckboxProps extends Omit<BaseCheckboxProps, 'onChange'> {
  onChange?: (checked: boolean) => void
  error?: string
}

const CheckboxBase: FC<PropsWithChildren<CheckboxProps>> = forwardRef(
  ({ children, value = false, onChange, ...props }, ref: Ref<CheckboxRef>) => {
    const handleChange = useCallback(
      (e: CheckboxChangeEvent) => {
        onChange?.(e.target.checked)
      },
      [onChange],
    )
    return (
      <Styled.Checkbox checked={value} {...props} onChange={handleChange} ref={ref}>
        {children}
      </Styled.Checkbox>
    )
  },
)

export const Checkbox = typedMemo(CheckboxBase)
