import { FC } from 'react'
import { Col } from 'antd'

import { useChangeBreadcrumbs, useForm } from 'hooks'

import { Input, Spacer, Tab, Tabs, Typography } from 'designSystem'
import { FormItem } from 'components'
import { createValidationSchema } from 'modules/trainings/utils/validationSchema'

import { breadcrumbs } from './constants/breadcrumbs'
import { TrainingTab } from 'modules/trainings/elements/TrainingTab'
import * as Styled from './styles'

export const TrainingCreate: FC = () => {
  useChangeBreadcrumbs(breadcrumbs)

  const { Form } = useForm({
    validationSchema: createValidationSchema,
  })

  const tabItems: Tab[] = [
    {
      key: 'monday',
      label: 'Monday',
      children: <TrainingTab />,
    },
  ]
  return (
    <Styled.Row>
      <Col span={8}>
        <Typography size="h4" color="white">
          Add Workout Program
        </Typography>
        <Spacer mb={4} />
        <Form>
          <FormItem name="name" label="Name">
            <Input placeholder="Workout Name" />
          </FormItem>
          <Spacer mb={4} />
          <Tabs items={tabItems} />
        </Form>
      </Col>
    </Styled.Row>
  )
}
