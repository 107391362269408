import theme from 'styles/theme'

import { ColorVariant } from '../types/colorVariant'

export const getStylesByColor = (color: ColorVariant) => {
  switch (color) {
    case 'secondary':
      return theme.colors.neutral[20]
    case 'error':
      return theme.colors.red[60]
    case 'white':
      return theme.colors.neutral[0]
    case 'link':
      return theme.colors.primary.main
    case 'inherit':
      return 'inherit'
    default:
      return theme.colors.neutral[10]
  }
}
