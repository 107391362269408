import { FC } from 'react'

import { Router } from './router'

export const App: FC = () => {
  return (
    <div className="App">
      <Router />
    </div>
  )
}
