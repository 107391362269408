import { gql } from '@apollo/client'

//TODO change query to enum
export const GET_EXERCISES = gql`
  query getExercises($getExerciseListInput: GetExerciseListInput!) {
    getAllExercises(getExerciseListInput: $getExerciseListInput) {
      total
      pages
      page
      data {
        image
        name
        equipments
        _id
      }
    }
  }
`

export const GET_EXERCISE = gql`
  query getExercise($id: ID!) {
    getExercise(id: $id) {
      name
      description
      image
      _id
      restTime
      equipments
      muscles
      healthConsiderations
      isFavorite
      isCreator
      isPublic
      bothSides
    }
  }
`
