import { WeekDay } from 'generated'
import { z } from 'zod'

export const createValidationSchema = z.object({
  name: z.string().min(4, 'Name must be at least 4 characters long').max(64, 'Name must be at most 64 characters long'),
  weekDay: z.array(z.nativeEnum(WeekDay)),
  description: z.string().nullable(),
})

export type TrainingsValues = z.infer<typeof createValidationSchema>

export const updateValidationSchema = z.object({}).merge(createValidationSchema).partial()
