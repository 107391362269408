import { forwardRef, ReactElement, Ref, useCallback, useEffect, useState } from 'react'
import { Select } from 'antd'
import { RefSelectProps, SelectProps as BaseSelectProps } from 'antd/lib/select'
import isEqual from 'react-fast-compare'

import { typedMemo } from 'types'
import theme from 'styles/theme'

import { Checkbox } from 'designSystem/Checkbox'
import { Icon } from 'designSystem/Icon'

import { SelectSingleOption } from './types/selectSingleOption'
import * as Styled from './styles'

export interface MultiSelectProps extends Omit<BaseSelectProps, 'mode' | 'onChange' | 'value'> {
  width?: number
  onChange?: (value?: (number | string)[], options?: SelectSingleOption[]) => void
  value?: (number | string)[]
}

export const MultiSelectBase = forwardRef(
  (
    { options = [], width, onChange, value, placeholder = 'Select', ...props }: MultiSelectProps,
    ref: Ref<RefSelectProps>,
  ): ReactElement => {
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState<SelectSingleOption[] | undefined>()
    const ids = selectedOptions?.map((option) => option.value)

    const handleChange = useCallback(
      (value?: (string | number)[], options?: SelectSingleOption | SelectSingleOption[]) => {
        if (Array.isArray(options)) {
          setSelectedOptions(options)
          onChange?.(value, options)
        }
      },
      [onChange],
    )

    const handleDropdownVisibleChange = useCallback((open: boolean) => {
      setDropDownOpen(open)
    }, [])

    useEffect(() => {
      const filteredOptions = options.filter((option) => value?.includes(String(option.value || '')))
      if (!isEqual(selectedOptions, filteredOptions)) {
        setSelectedOptions(filteredOptions as SelectSingleOption[])
      }

      //eslint-disable-next-line
    }, [value])

    return (
      <Styled.Wrapper $width={width}>
        <Select
          {...props}
          placeholder={placeholder}
          ref={ref}
          mode="multiple"
          value={value}
          onDropdownVisibleChange={handleDropdownVisibleChange}
          onChange={handleChange}
          suffixIcon={dropDownOpen ? undefined : <Icon icon="chevronDown" />}
          options={options as SelectSingleOption[]}
          dropdownStyle={{
            backgroundColor: theme.colors.neutral[30],
          }}
          optionRender={(option) => {
            return (
              <Styled.Item height={40} br="l" ai="center" gap={8}>
                <Checkbox checked={ids?.includes(`${option.value}`)} />
                {option.data.label}
              </Styled.Item>
            )
          }}
          dropdownRender={(menu) => <Styled.DropdownMenu>{menu}</Styled.DropdownMenu>}
        />
      </Styled.Wrapper>
    )
  },
)

export const MultiSelect = typedMemo(MultiSelectBase)
