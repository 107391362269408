import { FC } from 'react'

import theme from 'styles/theme'

import { Icon } from 'designSystem'

import * as Styled from './styles'

const notificationCount = 3

export const Notifications: FC = () => {
  return (
    <Styled.IconWrapper ai="center" jc="center" bg="neutral.50" br="l">
      <Icon icon="bell" color={theme.colors.neutral[5]} />
      <Styled.NotificationCount>{notificationCount}</Styled.NotificationCount>
    </Styled.IconWrapper>
  )
}
