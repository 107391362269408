import { FC, forwardRef, Ref } from 'react'
import { InputProps as BaseInputProps, InputRef } from 'antd/lib/input'

import { typedMemo } from 'types'

import * as Styled from './styles'

export interface InputProps extends BaseInputProps {
  hideChars?: boolean
  error?: string
  width?: number
}

const InputBase: FC<InputProps> = forwardRef(({ error, hideChars, width, ...props }, ref: Ref<InputRef>) => {
  const InputComponent = hideChars ? Styled.InputPassword : Styled.Input
  return (
    <Styled.Wrapper width={width}>
      <InputComponent {...props} ref={ref} status={error && 'error'} autoComplete="off" />
    </Styled.Wrapper>
  )
})

export const Input = typedMemo(InputBase)
