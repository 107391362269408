import { styled } from 'styled-components'

export const Button = styled.div`
  background: ${({ theme }) => theme.colors.neutral[30]};
  color: ${({ theme }) => theme.colors.neutral[10]};
  padding: 10px 0;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  &:hover {
    background: lighten(${({ theme }) => theme.colors.neutral[30]}, 10%);
  }
`
