import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { route } from 'constant'

import { showNotification } from 'designSystem'

import { useVerifyEmailMutation } from 'generated'

export const useVerifyEmail = () => {
  const navigator = useNavigate()
  const [verifyEmail] = useVerifyEmailMutation({
    onError: () => {
      navigator(route.login, {
        replace: true,
      })
    },
    onCompleted: () => {
      showNotification({ title: 'Email verified', type: 'success' })
    },
  })

  const handleVerifyEmail = useCallback(
    (token: string) => {
      verifyEmail({
        variables: {
          verifyEmailInput: {
            token,
          },
        },
      })
    },
    [verifyEmail],
  )

  return { verifyEmail: handleVerifyEmail }
}
