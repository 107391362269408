import { css, styled } from 'styled-components'

const notificationButtonStyles = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 16px;
  border: none;
  border-radius: 8px;
  border: 1px solid transparent;
  color: #181b1b;
  &:hover {
    border: 1px solid #181b1b;
  }
`

export const ConfirmButton = styled.button`
  ${notificationButtonStyles}
  background-color: #02fff1;
`

export const CancelButton = styled.button`
  ${notificationButtonStyles}
  background-color: #fff;
`
