import { ReactNode } from 'react'

import theme from 'styles/theme'

import { NotificationType } from '../types'

import { Icon } from '../../Icon'

const IconSize = 24

export const getIconByType = (type: NotificationType): ReactNode | null => {
  switch (type) {
    case 'info':
      return <Icon icon="warning" size={IconSize} color={theme.colors.secondary.blue} />
    case 'error':
      return <Icon icon="crossCircle" size={IconSize} color={theme.colors.red[60]} />
    case 'success':
      return <Icon icon="checkCircle" size={IconSize} color={theme.colors.green[60]} />
    case 'warning':
      return <Icon icon="warning" size={IconSize} color={theme.colors.neutral[0]} />
    default:
      return null
  }
}
