import { CSSProperties, ReactElement, ReactNode } from 'react'

import { FontWeight, typedMemo } from 'types'
import { getTruncatedText } from 'utils'

import { ColorVariant } from './types/colorVariant'
import { TypographySize } from './types/typographySize'
import * as Styled from './styles'

export interface TypographyProps {
  size?: TypographySize
  as?: keyof JSX.IntrinsicElements
  children: ReactNode
  color?: ColorVariant
  fontWeight?: FontWeight
  display?: 'block' | 'flex' | 'inline' | 'inline-flex' | 'inline-block'
  lineEllipsis?: number
  nowrap?: boolean
  truncateAfterCharAt?: number
  align?: CSSProperties['textAlign']
}

export const TypographyBase = ({
  children,
  as = 'span',
  color = 'primary',
  size = 'l',
  align,
  ...props
}: TypographyProps): ReactElement => {
  let child = children

  if (props.truncateAfterCharAt && typeof children === 'string') {
    child = getTruncatedText(children, props.truncateAfterCharAt)
  }

  return (
    <Styled.Typography align={align} as={as} color={color} size={size} {...props}>
      {child}
    </Styled.Typography>
  )
}

export const Typography = typedMemo(TypographyBase)
