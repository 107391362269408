import { FC } from 'react'

import { Container, Link, Spacer, Typography } from 'designSystem'
import { UnauthorizedLayout } from 'components'

import { useResendVerification } from './hooks/useResendVerification'

interface VerifyEmailProps {
  email: string
}

export const VerifyEmail: FC<VerifyEmailProps> = ({ email }) => {
  const { loading, resendVerification } = useResendVerification({ email })
  return (
    <UnauthorizedLayout>
      <Typography as="h3" size="h3" color="white">
        Verify your email
      </Typography>
      <Container fd="column" mt={4} mb={9} jc="center">
        <Typography size="l">We've sent a link to your email address: </Typography>
        <Spacer mb={2} />
        <Typography color="white" size="l">
          {email}
        </Typography>
      </Container>
      <Container ai="center" jc="center" mt={9} gap={4}>
        <Typography color="secondary">Didn't receive an email? </Typography>
        <Link loading={loading} onClick={resendVerification}>
          Resend
        </Link>
      </Container>
    </UnauthorizedLayout>
  )
}
