import { FC } from 'react'
import { Link } from 'react-router-dom'

import { useMenuItems } from 'hooks'
import { route } from 'constant'

import { Container, Icon, Spacer } from 'designSystem'

import type { SidebarItem } from './types/sidebarItem'
import * as Styled from './styles'

interface SidebarProps {
  sidebarItems: SidebarItem[]
}

export const Sidebar: FC<SidebarProps> = ({ sidebarItems }) => {
  const { activeMenuItem, handleClickMenuItem } = useMenuItems({ menuItems: sidebarItems })

  return (
    <Styled.Sidebar width={272}>
      <Link to={route.dashboard}>
        <Container mx={7}>
          <Icon icon="logo" />
        </Container>
      </Link>
      <Spacer mb={4} />
      <Styled.Menu
        selectedKeys={activeMenuItem ? [activeMenuItem] : []}
        items={sidebarItems}
        onClick={handleClickMenuItem}
      />
    </Styled.Sidebar>
  )
}
