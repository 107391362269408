import { create } from 'zustand'

import { User } from 'generated'

interface UserStore {
  user?: User
  setUser: (user?: User) => void
  removeUser: () => void
}

const useUserStore = create<UserStore>((set) => ({
  user: undefined,
  setUser: (user?: User) =>
    set({
      user,
    }),
  removeUser: () => set({ user: undefined }),
}))

export const useUser = () => useUserStore((state) => state.user)
export const useSetUser = () => useUserStore((state) => state.setUser)
export const useRemoveUser = () => useUserStore((state) => state.removeUser)
