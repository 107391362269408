import { gql } from '@apollo/client'

export const CREATE_EXERCISE = gql`
  mutation createExercise($createExerciseInput: CreateExerciseInput!) {
    createExercise(createExerciseInput: $createExerciseInput) {
      _id
      name
      image
    }
  }
`

export const TOGGLE_EXERCISE_FAVORITE = gql`
  mutation toggleFavorite($id: ID!) {
    toggleFavorite(id: $id) {
      isFavorite
    }
  }
`

export const DELETE_EXERCISE = gql`
  mutation removeExercise($id: ID!) {
    removeExercise(id: $id)
  }
`

export const UPDATE_EXERCISE = gql`
  mutation updateExercise($updateExerciseInput: UpdateExerciseInput!) {
    updateExercise(updateExerciseInput: $updateExerciseInput) {
      name
      description
      image
      _id
      restTime
      equipments
      muscles
      healthConsiderations
      isFavorite
      isCreator
      isPublic
      bothSides
    }
  }
`
