import { Avatar as BaseAvatar } from 'antd'
import { styled } from 'styled-components'

export const Avatar = styled(BaseAvatar)`
  background: ${({ theme }) => theme.colors.neutral[50]};
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 52px;
  height: 52px;
`
