import { cloneElement, FC, ReactElement } from 'react'

import { Controller, useFormContext, useFormState } from 'hooks'
import { typedMemo } from 'types'

import { Container, Typography } from 'designSystem'

import { getFormItemPrefix } from './utils/getFormItemPrefix'

export interface FormItemProps {
  name: string
  label?: string
  children: ReactElement
  defaultValue?: string | number
  hintText?: string
  height?: number | '100%'
}

const FormItemBase: FC<FormItemProps> = ({ children, name, defaultValue, hintText, label, height }) => {
  const { control } = useFormContext()
  const { errors } = useFormState({ name, exact: true })
  const currentError = errors[name]

  return (
    <Container fd="column" height={height}>
      {label && (
        <label htmlFor={name}>
          <Container mb={2}>
            <Typography size="m" color="white">
              {label}
            </Typography>
          </Container>
        </label>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field, fieldState }) =>
          cloneElement(children, {
            ...field,
            name,
            ref: field.ref,
            error: fieldState?.error?.message,
            suffix: getFormItemPrefix({
              suffix: children.props.suffix,
              error: fieldState?.error?.message,
            }),
          })
        }
      />
      {hintText && (
        <Container mt={2}>
          <Typography color={currentError ? 'error' : 'secondary'}>{hintText}</Typography>
        </Container>
      )}
    </Container>
  )
}

export const FormItem = typedMemo(FormItemBase)
