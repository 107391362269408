import { Button as BaseButton } from 'antd'
import { css, styled } from 'styled-components'

export const Button = styled(BaseButton)<{
  $iconOnly?: boolean
}>`
  gap: 4px;
  &&& .ant-btn-icon {
    margin-inline-end: 0;
  }

  &&&&&.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSize.xl};
    line-height: ${({ theme }) => theme.lineHeight.l};
    padding: 0 20px;
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius.m};

    & > svg {
      display: flex;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.neutral[0]};
    }
    &:focus {
      box-shadow: 0px 0px 0px 4px rgba(2, 255, 241, 0.1);
    }
    &:disabled {
      background: ${({ theme }) => theme.colors.neutral[30]};
      color: ${({ theme }) => theme.colors.neutral[20]};
      svg {
        fill: ${({ theme }) => theme.colors.neutral[20]};
      }
    }
  }

  & > .button-icon {
    display: inline-flex;
  }

  &&&&&&&.ant-btn-sm {
    font-size: ${({ theme }) => theme.fontSize.l};
    height: 40px;
    padding: 0 16px;
  }

  &&&&&.ant-btn-primary {
    background: ${({ theme }) => theme.colors.primary.main};
    color: ${({ theme }) => theme.colors.neutral[50]};
  }

  ${({ $iconOnly }) =>
    $iconOnly &&
    css`
      &&&&&&&&.ant-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 48px;
        &.ant-btn-sm {
          height: 44px;
          width: 44px;
        }
      }
    `}

  &&&&&.ant-btn-default {
    background: transparent;
    color: ${({ theme }) => theme.colors.neutral[0]};
    border: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    svg {
      fill: ${({ theme }) => theme.colors.neutral[0]};
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.neutral[5]};
      background: transparent;
    }
    &:disabled {
      border: 1px solid ${({ theme }) => theme.colors.neutral[20]};
      background: ${({ theme }) => theme.colors.neutral[30]};
    }
  }

  &&&&&.ant-btn-text {
    color: ${({ theme }) => theme.colors.neutral[0]};
    svg {
      fill: ${({ theme }) => theme.colors.neutral[0]};
    }
    &:hover {
      background: transparent;
    }
    &:disabled {
      background: transparent;
    }
  }
`
