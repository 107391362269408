import { showNotification } from 'designSystem'

import { CreateUserValues } from '../utils/validationSchema'

import { useSignUpMutation } from 'generated'

interface UseSignUpProps {
  setRequireVerification: (value: boolean) => void
}

export const useSignUp = ({ setRequireVerification }: UseSignUpProps) => {
  const [signUp, { loading }] = useSignUpMutation({
    onCompleted: () => {
      setRequireVerification(true)
      showNotification({ title: 'User created', type: 'success' })
    },
  })

  const handleSignUp = (values: Omit<CreateUserValues, 'confirmAgreement'>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { confirmPassword, ...rest } = values
    signUp({
      variables: {
        signUpInput: rest,
      },
    })
  }
  return { signUp: handleSignUp, loading }
}
