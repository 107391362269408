import { ReactComponent as IconActivity } from 'assets/icons/activity.svg'
import { ReactComponent as IconAddCircle } from 'assets/icons/add-circle.svg'
import { ReactComponent as IconArrowUp } from 'assets/icons/arrow-up.svg'
import { ReactComponent as IconBell } from 'assets/icons/bell.svg'
import { ReactComponent as IconCheckCircle } from 'assets/icons/check-circle.svg'
import { ReactComponent as IconChevronDown } from 'assets/icons/chevron-down.svg'
import { ReactComponent as IconCrossCircle } from 'assets/icons/cross-circle.svg'
import { ReactComponent as IconDumbbell } from 'assets/icons/dumbbell.svg'
import { ReactComponent as IconDumbbells } from 'assets/icons/dumbbells.svg'
import { ReactComponent as IconGoogle } from 'assets/icons/google.svg'
import { ReactComponent as IconHeart } from 'assets/icons/heart.svg'
import { ReactComponent as IconLock } from 'assets/icons/lock.svg'
import { ReactComponent as IconLogo } from 'assets/icons/logo.svg'
import { ReactComponent as IconMail } from 'assets/icons/mail.svg'
import { ReactComponent as IconMedical } from 'assets/icons/medical.svg'
import { ReactComponent as IconMuscle } from 'assets/icons/muscle.svg'
import { ReactComponent as IconPencil } from 'assets/icons/pencil.svg'
import { ReactComponent as IconPlay } from 'assets/icons/play.svg'
import { ReactComponent as IconSearch } from 'assets/icons/search.svg'
import { ReactComponent as IconSpinner } from 'assets/icons/spinner.svg'
import { ReactComponent as IconUser } from 'assets/icons/user-circle.svg'
import { ReactComponent as IconWarning } from 'assets/icons/warning.svg'
import { ReactComponent as IconWidgets } from 'assets/icons/widgets.svg'
import { ReactComponent as IconZoom } from 'assets/icons/zoom.svg'

export const iconToComponent = {
  user: IconUser,
  logo: IconLogo,
  lock: IconLock,
  mail: IconMail,
  warning: IconWarning,
  checkCircle: IconCheckCircle,
  google: IconGoogle,
  spinner: IconSpinner,
  crossCircle: IconCrossCircle,
  search: IconSearch,
  bell: IconBell,
  widgets: IconWidgets,
  muscle: IconMuscle,
  dumbbell: IconDumbbell,
  dumbbells: IconDumbbells,
  activity: IconActivity,
  addCircle: IconAddCircle,
  arrowUp: IconArrowUp,
  chevronDown: IconChevronDown,
  medical: IconMedical,
  heart: IconHeart,
  play: IconPlay,
  zoom: IconZoom,
  pencil: IconPencil,
}
