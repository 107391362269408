import { passwordValidation } from 'utils'

import { z } from 'zod'

export const validationSchema = z.object({
  email: z.string().email().min(1, 'Email is required'),
  password: passwordValidation,
})

export type LoginValues = z.infer<typeof validationSchema>
