import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { route } from 'constant'

import { showNotification } from 'designSystem'

import { ResetPasswordValues } from '../utils/validationSchema'

import { useResetPasswordMutation } from 'generated'

interface UseResetPasswordProps {
  token?: string
}

export const useResetPassword = ({ token }: UseResetPasswordProps) => {
  const navigate = useNavigate()
  const [resetPassword, { loading }] = useResetPasswordMutation({
    onCompleted: () => {
      showNotification({ title: 'Password changed', type: 'success' })
      navigate(route.login)
    },
  })

  const handleResetPassword = useCallback(
    (values: ResetPasswordValues) => {
      if (!token) {
        showNotification({ title: 'Not valid link', type: 'error' })
        navigate(route.login)
        return
      }
      resetPassword({
        variables: {
          resetPasswordInput: { password: values.password, token },
        },
      })
    },
    [token, resetPassword, navigate],
  )

  return {
    resetPassword: handleResetPassword,
    loading,
  }
}
