import { ChangeEvent, FC, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { debounce } from 'utils'
import { useSetPage, useSetSearch } from 'store'

import { Icon, Input } from 'designSystem'

import { searchAvailableLocations } from './constants/searchAvailableLocations'
import * as Styled from './styles'

export const Search: FC = () => {
  const setSearch = useSetSearch()
  const setPage = useSetPage()
  const location = useLocation()

  const isSearchVisible = searchAvailableLocations.some((availableLocation) =>
    location.pathname.endsWith(availableLocation),
  )

  useEffect(() => {
    if (!isSearchVisible) {
      setSearch('')
      setPage(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value)
      setPage(1)
    },
    [setSearch, setPage],
  )

  if (!isSearchVisible) {
    return null
  }

  return (
    <Styled.InputWrapper>
      <Input onChange={debounce(handleChangeInput)} placeholder="Search" suffix={<Icon icon="search" />} />
    </Styled.InputWrapper>
  )
}
