import { useGetSearchParams } from 'hooks'
import { pageLimit } from 'constant'
import { useGetPage, useGetSearch } from 'store'

import { Equipment, HealthConsideration, Muscle, useGetExercisesQuery } from 'generated'

export const useGetExercises = () => {
  const { equipment, healthConsideration, muscle } = useGetSearchParams()
  const search = useGetSearch()
  const currentPage = useGetPage()

  const { data, loading } = useGetExercisesQuery({
    skip: !muscle && !equipment && !healthConsideration,
    variables: {
      getExerciseListInput: {
        ...(muscle && { muscle: muscle as Muscle }),
        ...(equipment && { equipment: equipment as Equipment }),
        ...(healthConsideration && { healthConsideration: healthConsideration as HealthConsideration }),
        ...(search && { search }),
        page: currentPage || 1,
        limit: pageLimit || 7,
      },
    },
  })
  return { data, loading }
}
