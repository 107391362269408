import { createContext, useContext } from 'react'

export interface FormContextValue {
  triggerSubmit?: () => void
  isSubmitError?: boolean
  viewOnlyFields?: string[]
}

const defaultFormValue: FormContextValue = { triggerSubmit: undefined }

export const FormContext = createContext(defaultFormValue)

export const useFormContext = () => useContext<FormContextValue>(FormContext)
