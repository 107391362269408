import { css, styled } from 'styled-components'

import { BorderRadius } from 'types'

export const ImgWrapper = styled.span<{
  $borderRadius?: BorderRadius
}>`
  ${({ $borderRadius, theme }) =>
    $borderRadius &&
    css`
      &&&&& .ant-image img {
        border-radius: ${theme.borderRadius[$borderRadius]};
      }
    `}
`
