import { FC, ReactNode } from 'react'
import type { TabPaneProps, TabsProps as TabsPropsTabs } from 'antd'

import * as Styled from './styles'

import { Container } from '../Container'
import { Icon, IconName } from '../Icon'
import { Typography } from '../Typography'

export interface Tab extends TabPaneProps {
  key?: string
  label: ReactNode
  icon?: IconName
}

export interface TabsProps extends Omit<TabsPropsTabs, 'items'> {
  items?: Tab[]
}

export const Tabs: FC<TabsProps> = ({ items, ...props }) => {
  const tabsItems = items?.map(({ key, label, icon, ...rest }) => ({
    key: key || label?.toString(),
    label: (
      <Typography>
        <Container ai="center" gap={4}>
          {icon && <Icon size={14} icon={icon} />}
          {label}
        </Container>
      </Typography>
    ),
    ...rest,
  }))

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <Styled.TabBarWrapper width="100%">
      <DefaultTabBar {...props} />
    </Styled.TabBarWrapper>
  )

  return <Styled.Tabs items={tabsItems} renderTabBar={renderTabBar} {...props} />
}
