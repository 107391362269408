import { FC } from 'react'
import { Col, Input, Row } from 'antd'

import { Container } from 'designSystem'
import { FormItem } from 'components'

export const TrainingTab: FC = () => {
  return (
    <Container>
      <Row gutter={12} align="middle">
        <Col span={12}>
          <FormItem name="weekDay" label="Day of the week">
            <Input placeholder="Week Day" />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name="description" label="Description">
            <Input placeholder="Any description you need here" />
          </FormItem>
        </Col>
      </Row>
    </Container>
  )
}
