import { useNavigate } from 'react-router-dom'

import { setTokens } from 'api'
import { route } from 'constant'

import { showNotification } from 'designSystem'

import { useGoogleAuthMutation } from 'generated'

export const useGoogleAuth = () => {
  const navigate = useNavigate()
  const [googleAuth] = useGoogleAuthMutation({
    onCompleted: (data) => {
      setTokens({
        accessToken: data.googleAuth.accessToken,
        refreshToken: data.googleAuth.refreshToken,
      })
      showNotification({ title: 'Login successful', type: 'success' })
      navigate(route.dashboard)
    },
  })

  const handleGoogleAuth = (token: string) => {
    googleAuth({
      variables: {
        googleTokenInput: { token },
      },
    })
  }
  return { googleAuth: handleGoogleAuth }
}
