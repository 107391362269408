import { route } from 'constant'

export const getBreadcrumbs = (exerciseName?: string) => [
  {
    name: 'Exercises',
    link: route.exercises,
  },
  {
    name: exerciseName,
    link: route.exercises,
  },
]
