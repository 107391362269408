import { FC } from 'react'
import { useWatch } from 'react-hook-form'

import theme from 'styles/theme'

import { Container, Icon, Typography } from 'designSystem'

import { checkPasswordValidation } from './utils/checkPasswordValidation'
import { passwordRequirements } from '../../constants/passwordRequirements'
import * as Styled from './styles'

export const PasswordRequirements: FC = () => {
  const currentPassword: string = useWatch({ name: 'password' }) || ''

  const passwordValidation = checkPasswordValidation(currentPassword)

  return (
    <Container ai="flex-start" fd="column" py={4}>
      <Typography>The password must contain:</Typography>
      <Container mt={3} fw="wrap" gap={8}>
        {passwordRequirements.map((requirement, index) => (
          <Styled.Tag key={requirement}>
            <Container gap={4} ai="center">
              <Icon
                icon="checkCircle"
                color={passwordValidation[index] ? theme.colors.primary.main : theme.colors.neutral[10]}
              />
              <Typography size="m">{requirement}</Typography>
            </Container>
          </Styled.Tag>
        ))}
      </Container>
    </Container>
  )
}
