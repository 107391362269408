import { css, styled } from 'styled-components'

export const EditorWrapper = styled.div<{
  $height?: number
  $isFocused: boolean
  $readOnly: boolean
}>`
  height: ${({ $height }) => ($height ? `${$height}px` : '100%')};

  .quill {
    display: flex;
    flex-direction: column-reverse;
    border: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    height: 100%;
    ${({ $height }) =>
      $height &&
      css`
        height: ${$height}px;
      `}
    ${({ $isFocused }) =>
      $isFocused &&
      css`
        border: 1px solid ${({ theme }) => theme.colors.primary.main};
      `}

    a {
      color: ${({ theme }) => theme.colors.primary.main} !important;
    }
    ${({ $readOnly }) =>
      $readOnly &&
      css`
        border-color: transparent;
      `}
  }

  .ql-toolbar,
  .ql-container {
    border: none;
  }

  &&&&&&&&& .ql-toolbar .ql-fill {
    fill: ${({ theme }) => theme.colors.neutral[20]};
  }

  &&&&&& .ql-toolbar .ql-stroke {
    stroke: ${({ theme }) => theme.colors.neutral[20]};
  }

  &&&&&&& .ql-editor {
    color: ${({ theme }) => theme.colors.neutral[0]};
    font-size: ${({ theme }) => theme.fontSize.l};
  }

  button:hover .ql-fill,
  .ql-picker-label:hover .ql-fill {
    fill: ${({ theme }) => theme.colors.neutral[0]} !important;
    stroke: none;
  }

  button:hover .ql-stroke,
  .ql-picker-label:hover .ql-stroke {
    fill: none;
    stroke: ${({ theme }) => theme.colors.neutral[0]} !important;
  }

  & .ql-tooltip {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.neutral[5]};
    box-shadow: none;
    input {
      border-radius: 8px;
    }
  }
`
