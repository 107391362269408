import { styled } from 'styled-components'

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  .ant-input-affix-wrapper {
    border-color: transparent;
    height: 52px;
    padding: 0 27px;
  }
`
