import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { setTokens } from 'api'
import { ErrorCode } from 'enums'
import { route } from 'constant'

import { showNotification } from 'designSystem'

import { LoginValues } from '../utils/validationSchema'

import { useSignInMutation } from 'generated'

interface UseSignInProps {
  setRequireVerification: (value: boolean) => void
}

export const useSignIn = ({ setRequireVerification }: UseSignInProps) => {
  const navigate = useNavigate()
  const [signIn, { loading }] = useSignInMutation({
    onError: ({ graphQLErrors }) => {
      const [error] = graphQLErrors
      if (error.message.includes(ErrorCode.EmailNotVerified)) {
        setRequireVerification(true)
      }
    },
    onCompleted: (data) => {
      setTokens(data.signIn)
      showNotification({ title: 'Login successful', type: 'success' })
      navigate(route.dashboard)
    },
  })

  const handleSignIn = useCallback(
    (values: LoginValues) => {
      signIn({
        variables: {
          signInInput: values,
        },
      })
    },
    [signIn],
  )
  return { signIn: handleSignIn, loading }
}
