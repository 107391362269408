import { styled } from 'styled-components'

import { Container } from 'designSystem'

export const Wrapper = styled(Container)`
  border: 1px solid ${({ theme }) => theme.colors.neutral[30]};
  transition: border 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.neutral[20]};
  }
`

export const Image = styled.img`
  border-radius: ${({ theme }) => theme.borderRadius.l};
`
