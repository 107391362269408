import { FC, PropsWithChildren } from 'react'

import { TagColorVariant } from './types/colorVariant'
import * as Styled from './styles'

export interface TagProps {
  color: TagColorVariant
}

export const Tag: FC<PropsWithChildren<TagProps>> = ({ color, children }) => {
  return <Styled.Wrapper $color={color}>{children}</Styled.Wrapper>
}
