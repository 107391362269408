import { FC, forwardRef, PropsWithChildren, Ref } from 'react'
import { ButtonProps as BaseButtonProps } from 'antd/lib/button'

import { typedMemo } from 'types'

import { ButtonSize } from './types/buttonSize'
import { ButtonType } from './types/buttonType'
import * as Styled from './styles'

import { Icon, IconName } from '../Icon'

interface ButtonProps extends Omit<BaseButtonProps, 'size' | 'type' | 'icon'> {
  size?: ButtonSize
  type?: ButtonType
  iconLeft?: Exclude<IconName, 'logo'>
  iconRight?: Exclude<IconName, 'logo'>
}

export const ButtonBase: FC<PropsWithChildren<ButtonProps>> = forwardRef(
  ({ children, size = 'middle', type = 'default', iconRight, iconLeft, ...props }, ref: Ref<HTMLElement>) => {
    return (
      <Styled.Button
        ref={ref}
        type={type}
        size={size}
        $iconOnly={!children}
        {...props}
        icon={iconLeft && <Icon className="button-icon" size={20} icon={iconLeft} />}
      >
        {children}
        {iconRight && <Icon className="button-icon" size={20} icon={iconRight} />}
      </Styled.Button>
    )
  },
)

export const Button = typedMemo(ButtonBase)
