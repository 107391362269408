import { styled } from 'styled-components'

import { Container } from 'designSystem'

export const SpinWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`
