import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  Upload: { input: any; output: any }
}

export type AuthOutput = {
  __typename?: 'AuthOutput'
  accessToken: Token
  refreshToken: Token
}

export type CreateExerciseInput = {
  bothSides: Scalars['Boolean']['input']
  description: Scalars['String']['input']
  equipments?: InputMaybe<Array<Equipment>>
  healthConsiderations?: InputMaybe<Array<HealthConsideration>>
  image: Scalars['Upload']['input']
  isPublic: Scalars['Boolean']['input']
  muscles?: InputMaybe<Array<Muscle>>
  name: Scalars['String']['input']
  restTime: Scalars['Float']['input']
}

export type CreateTrainingInput = {
  name: Scalars['String']['input']
  workouts: Array<CreateWorkoutInput>
}

export type CreateWorkoutInput = {
  description: Scalars['String']['input']
  exercises: Array<WorkoutExerciseInput>
  weekDay: WeekDay
}

/** The equipment that the exercise requires */
export enum Equipment {
  Bands = 'Bands',
  Barbell = 'Barbell',
  Bodyweight = 'Bodyweight',
  Cable = 'Cable',
  Dumbbell = 'Dumbbell',
  Machine = 'Machine',
}

export type Exercise = {
  __typename?: 'Exercise'
  _id: Scalars['ID']['output']
  bothSides: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: User
  description: Scalars['String']['output']
  equipments?: Maybe<Array<Equipment>>
  favoriteBy?: Maybe<Array<User>>
  healthConsiderations?: Maybe<Array<HealthConsideration>>
  image: Scalars['String']['output']
  isPublic: Scalars['Boolean']['output']
  muscles?: Maybe<Array<Muscle>>
  name: Scalars['String']['output']
  restTime: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ExerciseListPaginated = {
  __typename?: 'ExerciseListPaginated'
  data: Array<Exercise>
  page: Scalars['Int']['output']
  pages: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type ExerciseOutput = {
  __typename?: 'ExerciseOutput'
  _id: Scalars['ID']['output']
  bothSides: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: User
  description: Scalars['String']['output']
  equipments?: Maybe<Array<Equipment>>
  favoriteBy?: Maybe<Array<User>>
  healthConsiderations?: Maybe<Array<HealthConsideration>>
  image: Scalars['String']['output']
  isCreator: Scalars['Boolean']['output']
  isFavorite: Scalars['Boolean']['output']
  isPublic: Scalars['Boolean']['output']
  muscles?: Maybe<Array<Muscle>>
  name: Scalars['String']['output']
  restTime: Scalars['Float']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ForgotPasswordInput = {
  email: Scalars['String']['input']
}

export type GetExerciseListInput = {
  equipment?: InputMaybe<Equipment>
  healthConsideration?: InputMaybe<HealthConsideration>
  limit?: InputMaybe<Scalars['Int']['input']>
  muscle?: InputMaybe<Muscle>
  page?: InputMaybe<Scalars['Int']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export type GoogleTokenInput = {
  token: Scalars['String']['input']
}

/** The health considerations for the exercise */
export enum HealthConsideration {
  BoneOrJointDisease = 'BoneOrJointDisease',
  Diabetes = 'Diabetes',
  HeartDisease = 'HeartDisease',
  HighBloodPressure = 'HighBloodPressure',
  LowBloodPressure = 'LowBloodPressure',
  Overweight = 'Overweight',
  Pregnant = 'Pregnant',
}

/** The muscles that the exercise targets */
export enum Muscle {
  Abs = 'Abs',
  Arms = 'Arms',
  Back = 'Back',
  Biceps = 'Biceps',
  Calves = 'Calves',
  Chest = 'Chest',
  Core = 'Core',
  Legs = 'Legs',
  Quads = 'Quads',
  Shoulders = 'Shoulders',
  Triceps = 'Triceps',
}

export type Mutation = {
  __typename?: 'Mutation'
  createExercise: Exercise
  createTraining: Training
  forgotPassword: Scalars['Boolean']['output']
  googleAuth: AuthOutput
  refreshTokens: AuthOutput
  removeExercise: Scalars['Boolean']['output']
  removeTraining: Training
  resendVerificationEmail: Scalars['Boolean']['output']
  resetPassword: Scalars['Boolean']['output']
  signIn: AuthOutput
  signUp: User
  toggleFavorite: ExerciseOutput
  updateExercise: ExerciseOutput
  updateTraining: Training
  uploadFile: Scalars['String']['output']
  verifyEmail: AuthOutput
}

export type MutationCreateExerciseArgs = {
  createExerciseInput: CreateExerciseInput
}

export type MutationCreateTrainingArgs = {
  createTrainingInput: CreateTrainingInput
}

export type MutationForgotPasswordArgs = {
  forgotPasswordInput: ForgotPasswordInput
}

export type MutationGoogleAuthArgs = {
  googleTokenInput: GoogleTokenInput
}

export type MutationRefreshTokensArgs = {
  refreshTokenInput: RefreshTokenInput
}

export type MutationRemoveExerciseArgs = {
  id: Scalars['ID']['input']
}

export type MutationRemoveTrainingArgs = {
  id: Scalars['Int']['input']
}

export type MutationResendVerificationEmailArgs = {
  resendVerificationEmailInput: ResendVerificationEmailInput
}

export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput
}

export type MutationSignInArgs = {
  signInInput: SignInInput
}

export type MutationSignUpArgs = {
  signUpInput: SignUpInput
}

export type MutationToggleFavoriteArgs = {
  id: Scalars['ID']['input']
}

export type MutationUpdateExerciseArgs = {
  updateExerciseInput: UpdateExerciseInput
}

export type MutationUpdateTrainingArgs = {
  updateTrainingInput: UpdateTrainingInput
}

export type MutationUploadFileArgs = {
  input: UploadFilesInput
}

export type MutationVerifyEmailArgs = {
  verifyEmailInput: VerifyEmailInput
}

export type Query = {
  __typename?: 'Query'
  getAllExercises: ExerciseListPaginated
  getExercise: ExerciseOutput
  me: User
  training: Training
  trainings: Array<Training>
}

export type QueryGetAllExercisesArgs = {
  getExerciseListInput: GetExerciseListInput
}

export type QueryGetExerciseArgs = {
  id: Scalars['ID']['input']
}

export type QueryTrainingArgs = {
  id: Scalars['Int']['input']
}

export type RefreshTokenInput = {
  refreshToken: Scalars['String']['input']
}

export type ResendVerificationEmailInput = {
  email: Scalars['String']['input']
}

export type ResetPasswordInput = {
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type SignInInput = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type SignUpInput = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  userName: Scalars['String']['input']
}

export type Token = {
  __typename?: 'Token'
  expiresIn: Scalars['Float']['output']
  token: Scalars['String']['output']
}

export type Training = {
  __typename?: 'Training'
  _id: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: User
  name: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  workouts: Array<Workout>
}

export type UpdateExerciseInput = {
  bothSides?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  equipments?: InputMaybe<Array<Equipment>>
  healthConsiderations?: InputMaybe<Array<HealthConsideration>>
  id: Scalars['String']['input']
  image?: InputMaybe<Scalars['Upload']['input']>
  isPublic?: InputMaybe<Scalars['Boolean']['input']>
  muscles?: InputMaybe<Array<Muscle>>
  name?: InputMaybe<Scalars['String']['input']>
  restTime?: InputMaybe<Scalars['Float']['input']>
}

export type UpdateTrainingInput = {
  id: Scalars['Int']['input']
  name?: InputMaybe<Scalars['String']['input']>
  workouts?: InputMaybe<Array<CreateWorkoutInput>>
}

export type UploadFilesInput = {
  file?: InputMaybe<Scalars['Upload']['input']>
}

export type User = {
  __typename?: 'User'
  _id: Scalars['ID']['output']
  createdAt: Scalars['DateTime']['output']
  email: Scalars['String']['output']
  googleId?: Maybe<Scalars['String']['output']>
  isVerified: Scalars['Boolean']['output']
  password?: Maybe<Scalars['String']['output']>
  role: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  userName: Scalars['String']['output']
}

export type VerifyEmailInput = {
  token: Scalars['String']['input']
}

/** Week day enum type. */
export enum WeekDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
}

export type Workout = {
  __typename?: 'Workout'
  _id: Scalars['ID']['output']
  description: Scalars['String']['output']
  exercises: Array<WorkoutExercise>
  exercisesOrder: Array<Scalars['ID']['output']>
  weekDay: WeekDay
}

export type WorkoutExercise = {
  __typename?: 'WorkoutExercise'
  exercise: Exercise
  repsCount: Scalars['Float']['output']
  setsCount: Scalars['Float']['output']
}

export type WorkoutExerciseInput = {
  exerciseId: Scalars['ID']['input']
  repCount: Scalars['Float']['input']
  setsCount: Scalars['Float']['input']
}

export type SignUpMutationVariables = Exact<{
  signUpInput: SignUpInput
}>

export type SignUpMutation = {
  __typename?: 'Mutation'
  signUp: { __typename?: 'User'; userName: string; email: string }
}

export type SignInMutationVariables = Exact<{
  signInInput: SignInInput
}>

export type SignInMutation = {
  __typename?: 'Mutation'
  signIn: {
    __typename?: 'AuthOutput'
    refreshToken: { __typename?: 'Token'; token: string; expiresIn: number }
    accessToken: { __typename?: 'Token'; token: string; expiresIn: number }
  }
}

export type GoogleAuthMutationVariables = Exact<{
  googleTokenInput: GoogleTokenInput
}>

export type GoogleAuthMutation = {
  __typename?: 'Mutation'
  googleAuth: {
    __typename?: 'AuthOutput'
    refreshToken: { __typename?: 'Token'; token: string; expiresIn: number }
    accessToken: { __typename?: 'Token'; token: string; expiresIn: number }
  }
}

export type ResendVerificationMutationVariables = Exact<{
  resendVerificationEmailInput: ResendVerificationEmailInput
}>

export type ResendVerificationMutation = { __typename?: 'Mutation'; resendVerificationEmail: boolean }

export type VerifyEmailMutationVariables = Exact<{
  verifyEmailInput: VerifyEmailInput
}>

export type VerifyEmailMutation = {
  __typename?: 'Mutation'
  verifyEmail: {
    __typename?: 'AuthOutput'
    refreshToken: { __typename?: 'Token'; token: string; expiresIn: number }
    accessToken: { __typename?: 'Token'; token: string; expiresIn: number }
  }
}

export type ForgotPasswordMutationVariables = Exact<{
  forgotPasswordInput: ForgotPasswordInput
}>

export type ForgotPasswordMutation = { __typename?: 'Mutation'; forgotPassword: boolean }

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordInput: ResetPasswordInput
}>

export type ResetPasswordMutation = { __typename?: 'Mutation'; resetPassword: boolean }

export type CreateExerciseMutationVariables = Exact<{
  createExerciseInput: CreateExerciseInput
}>

export type CreateExerciseMutation = {
  __typename?: 'Mutation'
  createExercise: { __typename?: 'Exercise'; _id: string; name: string; image: string }
}

export type ToggleFavoriteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ToggleFavoriteMutation = {
  __typename?: 'Mutation'
  toggleFavorite: { __typename?: 'ExerciseOutput'; isFavorite: boolean }
}

export type RemoveExerciseMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type RemoveExerciseMutation = { __typename?: 'Mutation'; removeExercise: boolean }

export type UpdateExerciseMutationVariables = Exact<{
  updateExerciseInput: UpdateExerciseInput
}>

export type UpdateExerciseMutation = {
  __typename?: 'Mutation'
  updateExercise: {
    __typename?: 'ExerciseOutput'
    name: string
    description: string
    image: string
    _id: string
    restTime: number
    equipments?: Array<Equipment> | null
    muscles?: Array<Muscle> | null
    healthConsiderations?: Array<HealthConsideration> | null
    isFavorite: boolean
    isCreator: boolean
    isPublic: boolean
    bothSides: boolean
  }
}

export type GetExercisesQueryVariables = Exact<{
  getExerciseListInput: GetExerciseListInput
}>

export type GetExercisesQuery = {
  __typename?: 'Query'
  getAllExercises: {
    __typename?: 'ExerciseListPaginated'
    total: number
    pages: number
    page: number
    data: Array<{
      __typename?: 'Exercise'
      image: string
      name: string
      equipments?: Array<Equipment> | null
      _id: string
    }>
  }
}

export type GetExerciseQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetExerciseQuery = {
  __typename?: 'Query'
  getExercise: {
    __typename?: 'ExerciseOutput'
    name: string
    description: string
    image: string
    _id: string
    restTime: number
    equipments?: Array<Equipment> | null
    muscles?: Array<Muscle> | null
    healthConsiderations?: Array<HealthConsideration> | null
    isFavorite: boolean
    isCreator: boolean
    isPublic: boolean
    bothSides: boolean
  }
}

export type CreateTrainingMutationVariables = Exact<{
  createTrainingInput: CreateTrainingInput
}>

export type CreateTrainingMutation = {
  __typename?: 'Mutation'
  createTraining: {
    __typename?: 'Training'
    _id: string
    name: string
    workouts: Array<{
      __typename?: 'Workout'
      weekDay: WeekDay
      description: string
      exercises: Array<{
        __typename?: 'WorkoutExercise'
        setsCount: number
        repsCount: number
        exercise: { __typename?: 'Exercise'; _id: string; name: string }
      }>
    }>
  }
}

export type GetMeQueryVariables = Exact<{ [key: string]: never }>

export type GetMeQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    userName: string
    email: string
    role: string
    _id: string
    createdAt: any
    updatedAt: any
    googleId?: string | null
    isVerified: boolean
  }
}

export const SignUpDocument = gql`
  mutation SignUp($signUpInput: SignUpInput!) {
    signUp(signUpInput: $signUpInput) {
      userName
      email
    }
  }
`
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      signUpInput: // value for 'signUpInput'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options)
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>
export const SignInDocument = gql`
  mutation SignIn($signInInput: SignInInput!) {
    signIn(signInInput: $signInInput) {
      refreshToken {
        token
        expiresIn
      }
      accessToken {
        token
        expiresIn
      }
    }
  }
`
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      signInInput: // value for 'signInInput'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options)
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>
export const GoogleAuthDocument = gql`
  mutation googleAuth($googleTokenInput: GoogleTokenInput!) {
    googleAuth(googleTokenInput: $googleTokenInput) {
      refreshToken {
        token
        expiresIn
      }
      accessToken {
        token
        expiresIn
      }
    }
  }
`
export type GoogleAuthMutationFn = Apollo.MutationFunction<GoogleAuthMutation, GoogleAuthMutationVariables>

/**
 * __useGoogleAuthMutation__
 *
 * To run a mutation, you first call `useGoogleAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleAuthMutation, { data, loading, error }] = useGoogleAuthMutation({
 *   variables: {
 *      googleTokenInput: // value for 'googleTokenInput'
 *   },
 * });
 */
export function useGoogleAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<GoogleAuthMutation, GoogleAuthMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GoogleAuthMutation, GoogleAuthMutationVariables>(GoogleAuthDocument, options)
}
export type GoogleAuthMutationHookResult = ReturnType<typeof useGoogleAuthMutation>
export type GoogleAuthMutationResult = Apollo.MutationResult<GoogleAuthMutation>
export type GoogleAuthMutationOptions = Apollo.BaseMutationOptions<GoogleAuthMutation, GoogleAuthMutationVariables>
export const ResendVerificationDocument = gql`
  mutation resendVerification($resendVerificationEmailInput: ResendVerificationEmailInput!) {
    resendVerificationEmail(resendVerificationEmailInput: $resendVerificationEmailInput)
  }
`
export type ResendVerificationMutationFn = Apollo.MutationFunction<
  ResendVerificationMutation,
  ResendVerificationMutationVariables
>

/**
 * __useResendVerificationMutation__
 *
 * To run a mutation, you first call `useResendVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationMutation, { data, loading, error }] = useResendVerificationMutation({
 *   variables: {
 *      resendVerificationEmailInput: // value for 'resendVerificationEmailInput'
 *   },
 * });
 */
export function useResendVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendVerificationMutation, ResendVerificationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResendVerificationMutation, ResendVerificationMutationVariables>(
    ResendVerificationDocument,
    options,
  )
}
export type ResendVerificationMutationHookResult = ReturnType<typeof useResendVerificationMutation>
export type ResendVerificationMutationResult = Apollo.MutationResult<ResendVerificationMutation>
export type ResendVerificationMutationOptions = Apollo.BaseMutationOptions<
  ResendVerificationMutation,
  ResendVerificationMutationVariables
>
export const VerifyEmailDocument = gql`
  mutation verifyEmail($verifyEmailInput: VerifyEmailInput!) {
    verifyEmail(verifyEmailInput: $verifyEmailInput) {
      refreshToken {
        token
        expiresIn
      }
      accessToken {
        token
        expiresIn
      }
    }
  }
`
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      verifyEmailInput: // value for 'verifyEmailInput'
 *   },
 * });
 */
export function useVerifyEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options)
}
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $forgotPasswordInput)
  }
`
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      forgotPasswordInput: // value for 'forgotPasswordInput'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options)
}
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput)
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordInput: // value for 'resetPasswordInput'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const CreateExerciseDocument = gql`
  mutation createExercise($createExerciseInput: CreateExerciseInput!) {
    createExercise(createExerciseInput: $createExerciseInput) {
      _id
      name
      image
    }
  }
`
export type CreateExerciseMutationFn = Apollo.MutationFunction<CreateExerciseMutation, CreateExerciseMutationVariables>

/**
 * __useCreateExerciseMutation__
 *
 * To run a mutation, you first call `useCreateExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExerciseMutation, { data, loading, error }] = useCreateExerciseMutation({
 *   variables: {
 *      createExerciseInput: // value for 'createExerciseInput'
 *   },
 * });
 */
export function useCreateExerciseMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateExerciseMutation, CreateExerciseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateExerciseMutation, CreateExerciseMutationVariables>(CreateExerciseDocument, options)
}
export type CreateExerciseMutationHookResult = ReturnType<typeof useCreateExerciseMutation>
export type CreateExerciseMutationResult = Apollo.MutationResult<CreateExerciseMutation>
export type CreateExerciseMutationOptions = Apollo.BaseMutationOptions<
  CreateExerciseMutation,
  CreateExerciseMutationVariables
>
export const ToggleFavoriteDocument = gql`
  mutation toggleFavorite($id: ID!) {
    toggleFavorite(id: $id) {
      isFavorite
    }
  }
`
export type ToggleFavoriteMutationFn = Apollo.MutationFunction<ToggleFavoriteMutation, ToggleFavoriteMutationVariables>

/**
 * __useToggleFavoriteMutation__
 *
 * To run a mutation, you first call `useToggleFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleFavoriteMutation, { data, loading, error }] = useToggleFavoriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleFavoriteMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleFavoriteMutation, ToggleFavoriteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ToggleFavoriteMutation, ToggleFavoriteMutationVariables>(ToggleFavoriteDocument, options)
}
export type ToggleFavoriteMutationHookResult = ReturnType<typeof useToggleFavoriteMutation>
export type ToggleFavoriteMutationResult = Apollo.MutationResult<ToggleFavoriteMutation>
export type ToggleFavoriteMutationOptions = Apollo.BaseMutationOptions<
  ToggleFavoriteMutation,
  ToggleFavoriteMutationVariables
>
export const RemoveExerciseDocument = gql`
  mutation removeExercise($id: ID!) {
    removeExercise(id: $id)
  }
`
export type RemoveExerciseMutationFn = Apollo.MutationFunction<RemoveExerciseMutation, RemoveExerciseMutationVariables>

/**
 * __useRemoveExerciseMutation__
 *
 * To run a mutation, you first call `useRemoveExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExerciseMutation, { data, loading, error }] = useRemoveExerciseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveExerciseMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveExerciseMutation, RemoveExerciseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveExerciseMutation, RemoveExerciseMutationVariables>(RemoveExerciseDocument, options)
}
export type RemoveExerciseMutationHookResult = ReturnType<typeof useRemoveExerciseMutation>
export type RemoveExerciseMutationResult = Apollo.MutationResult<RemoveExerciseMutation>
export type RemoveExerciseMutationOptions = Apollo.BaseMutationOptions<
  RemoveExerciseMutation,
  RemoveExerciseMutationVariables
>
export const UpdateExerciseDocument = gql`
  mutation updateExercise($updateExerciseInput: UpdateExerciseInput!) {
    updateExercise(updateExerciseInput: $updateExerciseInput) {
      name
      description
      image
      _id
      restTime
      equipments
      muscles
      healthConsiderations
      isFavorite
      isCreator
      isPublic
      bothSides
    }
  }
`
export type UpdateExerciseMutationFn = Apollo.MutationFunction<UpdateExerciseMutation, UpdateExerciseMutationVariables>

/**
 * __useUpdateExerciseMutation__
 *
 * To run a mutation, you first call `useUpdateExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExerciseMutation, { data, loading, error }] = useUpdateExerciseMutation({
 *   variables: {
 *      updateExerciseInput: // value for 'updateExerciseInput'
 *   },
 * });
 */
export function useUpdateExerciseMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateExerciseMutation, UpdateExerciseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateExerciseMutation, UpdateExerciseMutationVariables>(UpdateExerciseDocument, options)
}
export type UpdateExerciseMutationHookResult = ReturnType<typeof useUpdateExerciseMutation>
export type UpdateExerciseMutationResult = Apollo.MutationResult<UpdateExerciseMutation>
export type UpdateExerciseMutationOptions = Apollo.BaseMutationOptions<
  UpdateExerciseMutation,
  UpdateExerciseMutationVariables
>
export const GetExercisesDocument = gql`
  query getExercises($getExerciseListInput: GetExerciseListInput!) {
    getAllExercises(getExerciseListInput: $getExerciseListInput) {
      total
      pages
      page
      data {
        image
        name
        equipments
        _id
      }
    }
  }
`

/**
 * __useGetExercisesQuery__
 *
 * To run a query within a React component, call `useGetExercisesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExercisesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExercisesQuery({
 *   variables: {
 *      getExerciseListInput: // value for 'getExerciseListInput'
 *   },
 * });
 */
export function useGetExercisesQuery(
  baseOptions: Apollo.QueryHookOptions<GetExercisesQuery, GetExercisesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetExercisesQuery, GetExercisesQueryVariables>(GetExercisesDocument, options)
}
export function useGetExercisesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExercisesQuery, GetExercisesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetExercisesQuery, GetExercisesQueryVariables>(GetExercisesDocument, options)
}
export function useGetExercisesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetExercisesQuery, GetExercisesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetExercisesQuery, GetExercisesQueryVariables>(GetExercisesDocument, options)
}
export type GetExercisesQueryHookResult = ReturnType<typeof useGetExercisesQuery>
export type GetExercisesLazyQueryHookResult = ReturnType<typeof useGetExercisesLazyQuery>
export type GetExercisesSuspenseQueryHookResult = ReturnType<typeof useGetExercisesSuspenseQuery>
export type GetExercisesQueryResult = Apollo.QueryResult<GetExercisesQuery, GetExercisesQueryVariables>
export const GetExerciseDocument = gql`
  query getExercise($id: ID!) {
    getExercise(id: $id) {
      name
      description
      image
      _id
      restTime
      equipments
      muscles
      healthConsiderations
      isFavorite
      isCreator
      isPublic
      bothSides
    }
  }
`

/**
 * __useGetExerciseQuery__
 *
 * To run a query within a React component, call `useGetExerciseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExerciseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExerciseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExerciseQuery(baseOptions: Apollo.QueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetExerciseQuery, GetExerciseQueryVariables>(GetExerciseDocument, options)
}
export function useGetExerciseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetExerciseQuery, GetExerciseQueryVariables>(GetExerciseDocument, options)
}
export function useGetExerciseSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetExerciseQuery, GetExerciseQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetExerciseQuery, GetExerciseQueryVariables>(GetExerciseDocument, options)
}
export type GetExerciseQueryHookResult = ReturnType<typeof useGetExerciseQuery>
export type GetExerciseLazyQueryHookResult = ReturnType<typeof useGetExerciseLazyQuery>
export type GetExerciseSuspenseQueryHookResult = ReturnType<typeof useGetExerciseSuspenseQuery>
export type GetExerciseQueryResult = Apollo.QueryResult<GetExerciseQuery, GetExerciseQueryVariables>
export const CreateTrainingDocument = gql`
  mutation createTraining($createTrainingInput: CreateTrainingInput!) {
    createTraining(createTrainingInput: $createTrainingInput) {
      _id
      name
      workouts {
        weekDay
        description
        exercises {
          exercise {
            _id
            name
          }
          setsCount
          repsCount
        }
      }
    }
  }
`
export type CreateTrainingMutationFn = Apollo.MutationFunction<CreateTrainingMutation, CreateTrainingMutationVariables>

/**
 * __useCreateTrainingMutation__
 *
 * To run a mutation, you first call `useCreateTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingMutation, { data, loading, error }] = useCreateTrainingMutation({
 *   variables: {
 *      createTrainingInput: // value for 'createTrainingInput'
 *   },
 * });
 */
export function useCreateTrainingMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTrainingMutation, CreateTrainingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTrainingMutation, CreateTrainingMutationVariables>(CreateTrainingDocument, options)
}
export type CreateTrainingMutationHookResult = ReturnType<typeof useCreateTrainingMutation>
export type CreateTrainingMutationResult = Apollo.MutationResult<CreateTrainingMutation>
export type CreateTrainingMutationOptions = Apollo.BaseMutationOptions<
  CreateTrainingMutation,
  CreateTrainingMutationVariables
>
export const GetMeDocument = gql`
  query getMe {
    me {
      userName
      email
      role
      _id
      createdAt
      updatedAt
      googleId
      isVerified
    }
  }
`

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options)
}
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options)
}
export function useGetMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options)
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>
export type GetMeSuspenseQueryHookResult = ReturnType<typeof useGetMeSuspenseQuery>
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>
