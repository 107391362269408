import { passwordValidation } from 'utils'

import { z } from 'zod'

export const validationSchema = z
  .object({
    password: passwordValidation,
    confirmPassword: z.string().min(1, 'Password must be at least 8 characters long'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  })

export type ResetPasswordValues = z.infer<typeof validationSchema>
