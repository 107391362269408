import { useCallback } from 'react'

import { ForgotPasswordValues } from '../utils/validationSchema'

import { useForgotPasswordMutation } from 'generated'

interface UseForgotPasswordProps {
  setUserEmail: (email: string) => void
}

export const useForgotPassword = ({ setUserEmail }: UseForgotPasswordProps) => {
  const [forgotPassword, { loading }] = useForgotPasswordMutation({
    onCompleted: (_, payload) => {
      setUserEmail(payload?.variables?.forgotPasswordInput?.email)
    },
  })

  const handleForgotPassword = useCallback(
    (values: ForgotPasswordValues) => {
      forgotPassword({
        variables: {
          forgotPasswordInput: values,
        },
      })
    },
    [forgotPassword],
  )
  return { forgotPassword: handleForgotPassword, loading }
}
