import { fileValidation } from 'utils'

import { Equipment, HealthConsideration, Muscle } from 'generated'
import { z } from 'zod'

export const createValidationSchema = z.object({
  name: z.string().min(4, 'Name must be at least 4 characters long'),
  image: fileValidation,
  description: z.string().default(''),
  restTime: z.number().min(1, 'Rest time must be at least 1 second').max(180, 'Max number is 180 seconds'),
  bothSides: z.boolean().default(false),
  isPublic: z.boolean().default(false),
  equipments: z.array(z.nativeEnum(Equipment)).default([]),
  muscles: z.array(z.nativeEnum(Muscle)).default([]),
  healthConsiderations: z.array(z.nativeEnum(HealthConsideration)).default([]),
})

export type ExerciseValues = z.infer<typeof createValidationSchema>

export const updateValidationSchema = z.object({}).merge(createValidationSchema).partial()
