import { ThemeConfig } from 'antd/lib'
import type { DefaultTheme } from 'styled-components'

import { BorderRadius, FontSize, FontWeight, LineHeight, SpacerLevel, ZIndexLevel } from 'types'
import { HeaderType } from 'types/headersType'
import { ScreenType } from 'types/screenType'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      primary: {
        main: '#02FFF1'
        dark: '#101212'
        blue: {
          10: '#DDFFFD'
          60: '#02FFF1'
          80: '#172626'
        }
      }
      secondary: {
        green: '#00A74A'
        blue: '#009CCF '
        purple: '#7E53CF'
        pink: '#EB2F96'
        yellow: '#FAC515'
      }
      orange: {
        10: '#FFF0E0'
        60: '#FF8200'
        80: '#7A3F00'
        100: '#180D01'
      }
      red: {
        10: '#FFE0E6'
        60: '#E4002B'
        80: '#99001C'
      }
      neutral: {
        0: '#ffffff'
        5: '#F2F3F6'
        10: '#B0B7C3'
        20: '#8A94A6'
        25: '#606877'
        30: '#2C3131'
        50: '#181B1B'
        60: '#101212'
      }
      blue: {
        10: '#E5F9FF'
        60: '#009CCF'
        80: '#006BA8'
        100: '#052130'
      }
      green: {
        10: '#E5FFF2'
        60: '#00A74A'
        80: '#00662D'
        100: '#12251B'
      }
    }
    fontFamily: Record<string, string>
    fontWeight: Record<FontWeight, number>
    fontSize: Record<FontSize, string>
    lineHeight: Record<LineHeight, string>
    borderRadius: Record<BorderRadius, string>
    headers: Record<Exclude<ScreenType, 'tablet'>, Record<string, Record<HeaderType, string>>>
    breakpoints: Record<ScreenType, string>
    spacers: Record<SpacerLevel, string>
    zIndexLevel: Record<ZIndexLevel, number>
  }
}

const theme: DefaultTheme = {
  colors: {
    primary: {
      main: '#02FFF1',
      dark: '#101212',
      blue: {
        10: '#DDFFFD',
        60: '#02FFF1',
        80: '#172626',
      },
    },
    secondary: {
      green: '#00A74A',
      blue: '#009CCF ',
      purple: '#7E53CF',
      pink: '#EB2F96',
      yellow: '#FAC515',
    },
    orange: {
      10: '#FFF0E0',
      60: '#FF8200',
      80: '#7A3F00',
      100: '#180D01',
    },
    red: {
      10: '#FFE0E6',
      60: '#E4002B',
      80: '#99001C',
    },
    neutral: {
      0: '#ffffff',
      5: '#F2F3F6',
      10: '#B0B7C3',
      20: '#8A94A6',
      25: '#606877',
      30: '#2C3131',
      50: '#181B1B',
      60: '#101212',
    },
    blue: {
      10: '#E5F9FF',
      60: '#009CCF',
      80: '#006BA8',
      100: '#052130',
    },
    green: {
      10: '#E5FFF2',
      60: '#00A74A',
      80: '#00662D',
      100: '#12251B',
    },
  },
  spacers: {
    '0': '0px',
    '1': '2px',
    '2': '4px',
    '3': '8px',
    '4': '16px',
    '5': '20px',
    '6': '22px',
    '7': '24px',
    '8': '28px',
    '9': '32px',
    '10': '40px',
    '11': '48px',
    '12': '56px',
    '13': '64px',
    '14': '100px',
  },
  fontFamily: {
    primary: 'Inter',
  },
  headers: {
    desktop: {
      fontSize: {
        h1: '80px',
        h2: '56px',
        h3: '40px',
        h4: '32px',
        h5: '24px',
        h6: '20px',
      },
      lineHeight: {
        h1: '96px',
        h2: '67px',
        h3: '48px',
        h4: '38px',
        h5: '29px',
        h6: '24px',
      },
    },
    mobile: {
      fontSize: {
        h1: '36px',
        h2: '28px',
        h3: '24px',
        h4: '20px',
        h5: '16px',
        h6: '14px',
      },
      lineHeight: {
        h1: '44px',
        h2: '34px',
        h3: '29px',
        h4: '24px',
        h5: '20px',
        h6: '18px',
      },
    },
  },
  breakpoints: {
    desktop: '1440px',
    tablet: '768px',
    mobile: '360px',
  },
  fontSize: {
    m: '12px',
    l: '14px',
    xl: '16px',
  },
  lineHeight: {
    m: '16px',
    l: '20px',
    xl: '24px',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  borderRadius: {
    xs: '2px',
    s: '4px',
    m: '8px',
    l: '12px',
    xl: '16px',
    full: '50%',
  },
  zIndexLevel: {
    notifications: 1052,
  },
}

const baseFieldConfig = {
  lineHeight: 1.5,
  controlHeight: 40,
  colorBgContainer: theme.colors.neutral[50],
  colorText: theme.colors.neutral[0],
  colorBorder: theme.colors.neutral[20],
  fontSize: parseInt(theme.fontSize.l),
  colorError: theme.colors.red[60],
  colorTextPlaceholder: theme.colors.neutral[20],
  colorPrimaryHover: theme.colors.primary.main,
}

export const configProviderSetup: ThemeConfig = {
  components: {
    Button: {
      fontSize: parseInt(theme.fontSize.xl),
      fontSizeSM: parseInt(theme.fontSize.l),
      boxShadow: 'none',
      colorText: theme.colors.neutral[50],
      colorPrimaryBg: theme.colors.primary.main,
      borderRadius: parseInt(theme.borderRadius.m),
      controlHeight: 48,
    },
    Input: {
      ...baseFieldConfig,
    },
    InputNumber: {
      ...baseFieldConfig,
    },
    Select: {
      ...baseFieldConfig,
    },
  },
}

export default theme
