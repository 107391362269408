import { exerciseRoutes } from 'constant'

import { ExerciseEdit } from '../routes/ExerciseEdit'
import { ExerciseView } from '../routes/ExerciseView'

export const routes = [
  {
    path: exerciseRoutes.view,
    element: <ExerciseView />,
  },
  {
    path: exerciseRoutes.edit,
    element: <ExerciseEdit />,
  },
  {
    path: '*',
    element: <>Not Found</>,
  },
]
