import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Spin } from 'antd'

import { getAccessToken } from 'api'
import { useGetMe } from 'hooks'
import { route } from 'constant'

import { AuthLayout } from 'components'
import { Login, Register } from 'modules/auth'
import { ForgotPassword } from 'modules/auth/ForgotPassword'
import { ResetPassword } from 'modules/auth/ResetPassword'
import { Dashboard } from 'modules/dashboard'
import { Exercises } from 'modules/exercises'
import { Profile } from 'modules/profile'
import { PrivacyPolicy } from 'modules/terms/PrivacyPolicy'
import { TermsAndConditions } from 'modules/terms/TermsAndConditions'
import { Trainings } from 'modules/trainings'

import * as Styled from './styles'

export const Router: FC = () => {
  const token = getAccessToken()
  const { loading, isLoggedIn, error } = useGetMe({ skip: !token })

  if (loading && !error) {
    return (
      <Styled.SpinWrapper>
        <Spin spinning size="large" />
      </Styled.SpinWrapper>
    )
  }
  return (
    <>
      {isLoggedIn ? (
        <AuthLayout>
          <Routes>
            <Route path={route.dashboard} element={<Dashboard />} />
            <Route path={`${route.trainings}/*`} element={<Trainings />} />
            <Route path={`${route.exercises}/*`} element={<Exercises />} />
            <Route path={route.profile} element={<Profile />} />
            <Route path="*" element={<Navigate to={route.dashboard} />} />
          </Routes>
        </AuthLayout>
      ) : (
        <Routes>
          <Route path={route.register} element={<Register />} />
          <Route path={route.login} element={<Login />} />
          <Route path={route.forgotPassword} element={<ForgotPassword />} />
          <Route path={route.terms} element={<TermsAndConditions />} />
          <Route path={route.privacy} element={<PrivacyPolicy />} />
          <Route path={route.resetPassword} element={<ResetPassword />} />
          <Route path="*" element={<Navigate to={route.login} />} />
        </Routes>
      )}
    </>
  )
}
