import { styled } from 'styled-components'

export const Wrapper = styled.span`
  && .ant-switch {
    & .ant-switch-handle {
      &:before {
        background-color: ${({ theme }) => theme.colors.neutral[5]};
      }
    }
    .ant-switch-inner {
      background-color: ${({ theme }) => theme.colors.neutral[30]};
    }
    &.ant-switch-checked {
      .ant-switch-inner {
        background-color: ${({ theme }) => theme.colors.primary.main};
      }
    }
  }
`
