import { FC } from 'react'
import { AvatarProps as BaseAvatarProps } from 'antd/lib/avatar'

import { typedMemo } from 'types'

import * as Styled from './styles'

import { User } from 'generated'

interface AvatarProps extends BaseAvatarProps {
  user?: User
}

export const AvatarBase: FC<AvatarProps> = ({ user, ...rest }) => {
  if (!user) return null

  const username = user.userName
    .split(' ')
    .map((name) => name[0])
    .join('')
    .toUpperCase()
  return <Styled.Avatar {...rest}>{username}</Styled.Avatar>
}

export const Avatar = typedMemo(AvatarBase)
