import { gql } from '@apollo/client'

export const REGISTER_USER = gql`
  mutation SignUp($signUpInput: SignUpInput!) {
    signUp(signUpInput: $signUpInput) {
      userName
      email
    }
  }
`

export const LOGIN_USER = gql`
  mutation SignIn($signInInput: SignInInput!) {
    signIn(signInInput: $signInInput) {
      refreshToken {
        token
        expiresIn
      }
      accessToken {
        token
        expiresIn
      }
    }
  }
`

export const GOOGLE_AUTH = gql`
  mutation googleAuth($googleTokenInput: GoogleTokenInput!) {
    googleAuth(googleTokenInput: $googleTokenInput) {
      refreshToken {
        token
        expiresIn
      }
      accessToken {
        token
        expiresIn
      }
    }
  }
`

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerification($resendVerificationEmailInput: ResendVerificationEmailInput!) {
    resendVerificationEmail(resendVerificationEmailInput: $resendVerificationEmailInput)
  }
`

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($verifyEmailInput: VerifyEmailInput!) {
    verifyEmail(verifyEmailInput: $verifyEmailInput) {
      refreshToken {
        token
        expiresIn
      }
      accessToken {
        token
        expiresIn
      }
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordInput: $forgotPasswordInput)
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
    resetPassword(resetPasswordInput: $resetPasswordInput)
  }
`
