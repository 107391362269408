import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { useChangeBreadcrumbs } from 'hooks'

import { breadcrumbs } from './constants/breadcrumbs'
import { routes } from './constants/routes'

export const Trainings: FC = () => {
  useChangeBreadcrumbs(breadcrumbs)
  return (
    <>
      <Outlet />
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </>
  )
}
