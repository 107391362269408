import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Row } from 'antd'

import { useChangeBreadcrumbs } from 'hooks'
import { getEquipmentOptionsLabel, getHealthConsiderationLabel, getMuscleOptionLabel } from 'constant'

import { Container, Editor, Image, Loader, Spacer, Tag, Typography } from 'designSystem'
import { InternetSearch } from 'components'

import { getBreadcrumbs } from './utils/getBreadCrumbs'
import { Actions } from '../../elements/Actions'
import { LikeExercise } from '../../elements/LikeExercise'

import { useGetExerciseQuery } from 'generated'

export const ExerciseView: FC = () => {
  const { id } = useParams()
  const { data, loading } = useGetExerciseQuery({ variables: { id: String(id) }, skip: !id })

  useChangeBreadcrumbs(getBreadcrumbs(data?.getExercise.name))

  if (loading) {
    return <Loader />
  }
  return (
    <Container gap="20" width="100%">
      <Container fd="column" gap="20">
        <Image borderRadius="m" src={data?.getExercise.image} width={320} height={320} />
        <LikeExercise id={String(id)} isFavorite={!!data?.getExercise.isFavorite} />
        {data?.getExercise.isCreator && <Actions id={String(id)} />}
      </Container>
      <Container br="l" bg="neutral.60" width="100%" pa={7} fd="column">
        <Typography color="white" size="h4">
          {data?.getExercise.name}
        </Typography>
        <Row gutter={16}>
          {!!data?.getExercise.equipments?.length && (
            <Col span={8}>
              <Spacer mb={6} />
              <Typography size="xl">Equipment:</Typography>
              <Container gap="8" mt={3} flexWrap="wrap">
                {data?.getExercise.equipments.map((equipment) => {
                  return (
                    <Tag key={equipment} color="green">
                      {getEquipmentOptionsLabel(equipment)}
                    </Tag>
                  )
                })}
              </Container>
            </Col>
          )}
          {!!data?.getExercise.muscles?.length && (
            <Col span={8}>
              <Spacer mb={6} />
              <Typography size="xl">Muscle Group:</Typography>
              <Container gap="8" mt={3} flexWrap="wrap">
                {data?.getExercise.muscles.map((muscle) => {
                  return (
                    <Tag key={muscle} color="blue">
                      {getMuscleOptionLabel(muscle)}
                    </Tag>
                  )
                })}
              </Container>
            </Col>
          )}
          {!!data?.getExercise.healthConsiderations?.length && (
            <Col span={8}>
              <Spacer mb={6} />
              <Typography size="xl">Health considerations:</Typography>
              <Container gap="8" mt={3} flexWrap="wrap">
                {data?.getExercise.healthConsiderations.map((healthConsideration) => {
                  return (
                    <Tag key={healthConsideration} color="brown">
                      {getHealthConsiderationLabel(healthConsideration)}
                    </Tag>
                  )
                })}
              </Container>
            </Col>
          )}
        </Row>
        <Spacer mb={6} />
        <Container>
          <Editor value={data?.getExercise.description} readOnly />
        </Container>
        <Spacer mb={6} />
        <InternetSearch name={data?.getExercise.name} />
      </Container>
    </Container>
  )
}
