import { hasLettersRegExp, hasNumbersRegExp, hasUpperCaseRegExp, noSpaceRegExp } from 'constant'
import { maxPasswordLength, minPasswordLength } from 'utils'

export const checkPasswordValidation = (password: string): boolean[] => {
  const hasUpperCase = hasUpperCaseRegExp.test(password)
  const hasNumbers = hasNumbersRegExp.test(password)
  const hasLetters = hasLettersRegExp.test(password)
  const noSpace = password.length > 0 && noSpaceRegExp.test(password)
  const from8to64 = password.length >= minPasswordLength && password.length <= maxPasswordLength

  // should be in correct order
  return [from8to64, hasLetters, noSpace, hasNumbers, hasUpperCase]
}
