import { FC } from 'react'

import { Button } from 'designSystem'

import { generateGoogleLink } from './utils/generateGoogleLink'
import { generateYoutubeLink } from './utils/generateYoutubeLink'
import * as Styled from './styles'

interface InternetSearchProps {
  name?: string
}

export const InternetSearch: FC<InternetSearchProps> = ({ name }) => {
  if (!name) return null
  return (
    <Styled.Wrapper gap={24}>
      <a href={generateYoutubeLink(name)} target="_blank">
        <Button iconLeft="play" size="small">
          View on YouTube
        </Button>
      </a>

      <a href={generateGoogleLink(name)} target="_blank">
        <Button iconLeft="zoom" size="small">
          More about this exercise
        </Button>
      </a>
    </Styled.Wrapper>
  )
}
