import { create } from 'zustand'

import type { Breadcrumb } from 'components'

interface GlobalStore {
  search?: string
  page: number
  setSearch: (search?: string) => void
  setPage: (page: number) => void
  breadcrumbs?: Breadcrumb[]
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void
}

const useGlobalStore = create<GlobalStore>((set) => ({
  search: undefined,
  breadcrumbs: [],
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) =>
    set({
      breadcrumbs,
    }),
  setSearch: (search?: string) =>
    set({
      search,
    }),
  page: 1,
  setPage: (page: number) =>
    set({
      page,
    }),
}))

export const useGetSearch = () => useGlobalStore((state) => state.search)
export const useSetSearch = () => useGlobalStore((state) => state.setSearch)

export const useGetPage = () => useGlobalStore((state) => state.page)
export const useSetPage = () => useGlobalStore((state) => state.setPage)

export const useGetBreadcrumbs = () => useGlobalStore((state) => state.breadcrumbs)
export const useSetBreadcrumbs = () => useGlobalStore((state) => state.setBreadcrumbs)
