import { passwordValidation } from 'utils/validation'

import { z } from 'zod'

export const validationSchema = z
  .object({
    password: passwordValidation,
    confirmPassword: z.string().min(1, 'Password must be at least 8 characters long'),
    userName: z
      .string()
      .min(4, 'Username must be at least 4 characters long')
      .max(32, 'Username must be less than 32 characters long'),
    email: z.string().email().min(1, 'Email is required'),
    confirmAgreement: z.boolean().refine((value) => value === true, {
      message: 'You must agree to the terms and conditions',
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  })

export type CreateUserValues = z.infer<typeof validationSchema>
