import { FC } from 'react'

import { Icon } from 'designSystem'

import { useGoogleAuth } from '../../hooks/useGoogleAuth'
import * as Styled from './styles'

import { useGoogleLogin } from '@react-oauth/google'

export const GoogleButton: FC = () => {
  const { googleAuth } = useGoogleAuth()
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      googleAuth(tokenResponse.access_token as string)
    },
  })
  return (
    <Styled.Button onClick={login as any}>
      <Icon icon="google" />
      Sign In With Google
    </Styled.Button>
  )
}
