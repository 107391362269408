import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { route } from 'constant'

import { Container, Spacer, Tag, Typography } from 'designSystem'

import * as Styled from './styles'

import { Equipment } from 'generated'

interface SingleExerciseProps {
  image: string
  name: string
  equipments?: Equipment[] | null
  _id: string
}

export const SingleExercise: FC<SingleExerciseProps> = ({ image, name, equipments, _id }) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(`${route.exercises}/${_id}`, { state: name })
  }, [_id, navigate, name])

  return (
    <Styled.Wrapper onClick={handleClick} pa={3} br="l" gap={12}>
      <Styled.Image src={image} height={80} width={80} />
      <Container fd="column">
        <Typography color="white" size="xl">
          {name}
        </Typography>
        {!!equipments?.length && (
          <>
            <Spacer mb={3} />
            <Container gap={8}>
              {equipments.map((equipment) => (
                <Tag key={equipment} color="green">
                  {equipment}
                </Tag>
              ))}
            </Container>
          </>
        )}
      </Container>
    </Styled.Wrapper>
  )
}
