import { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { route } from 'constant'

import { Button, Checkbox, Container, Divider, Icon, Input, Link, Spacer, Typography } from 'designSystem'
import { FormItem, UnauthorizedLayout } from 'components'

import { useSignUp } from './hooks/useSignUp'
import { CreateUserValues, validationSchema } from './utils/validationSchema'
import { VerifyEmail } from '../elements/VerifyEmail'
import { PasswordRequirements } from './elements/PasswordRequirements'
import * as Styled from './styles'

import { GoogleAuth } from '../GoogleAuth'

export const Register: FC = () => {
  const [requireVerification, setRequireVerification] = useState(false)
  const form = useForm<CreateUserValues>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const email = form.watch('email')

  const { signUp, loading } = useSignUp({ setRequireVerification })

  const onSubmit = form.handleSubmit((variables) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { confirmAgreement, ...rest } = variables
    signUp(rest)
  })

  if (requireVerification) {
    return <VerifyEmail email={email} />
  }

  return (
    <UnauthorizedLayout>
      <Typography as="h3" size="h3" color="white">
        Welcome to FitMotion
      </Typography>
      <Spacer mb={4} />
      <Typography size="l">Please enter credentials that will be used through the registration process.</Typography>
      <Container mt={9} fd="column" mb={6}>
        <GoogleAuth />
      </Container>
      <Divider>or</Divider>
      <Spacer mb={2} />
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Container gap={16} fd="column">
            <FormItem label="Name" name="userName">
              <Input placeholder="Name" prefix={<Icon size={16} icon="user" />} />
            </FormItem>
            <FormItem label="Email" name="email">
              <Input placeholder="Email" prefix={<Icon size={16} icon="mail" />} />
            </FormItem>
            <FormItem label="Password" name="password">
              <Input hideChars placeholder="Password" prefix={<Icon size={16} icon="lock" />} />
            </FormItem>
            <FormItem label="Confirm Password" name="confirmPassword">
              <Input hideChars placeholder="Confirm Password" prefix={<Icon size={16} icon="lock" />} />
            </FormItem>
          </Container>
          <PasswordRequirements />
          <Container ai="center" gap={8}>
            <FormItem name="confirmAgreement">
              <Checkbox />
            </FormItem>
            <Styled.TermsWrapper>
              <Typography>
                I have read and agree to the <Styled.Link to={route.terms}>Terms and Conditions</Styled.Link> and the{' '}
                <Styled.Link to={route.privacy}>Privacy Policy</Styled.Link>
              </Typography>
            </Styled.TermsWrapper>
          </Container>
          <Container mt={8} fd="column">
            <Button htmlType="submit" size="small" type="primary" loading={loading}>
              Sign Up
            </Button>
          </Container>
          <Container ai="center" jc="center" mt={9} gap={4}>
            <Typography>Already have an account?</Typography>
            <Link to={route.login}>
              <Typography color="link">Sign In</Typography>
            </Link>
          </Container>
        </form>
      </FormProvider>
    </UnauthorizedLayout>
  )
}
