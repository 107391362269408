import { css, styled } from 'styled-components'

import { getStylesByColor } from './utils/getStylesByColor'
import { getStylesBySize } from './utils/getStylesBySize'

import type { TypographyProps } from './Typography'

export const Typography = styled.div<TypographyProps>`
  text-align: ${({ align }) => align || 'left'};
  display: ${({ display = 'inline-block' }) => display};
  font-weight: ${({ fontWeight = 'medium', theme }) => theme.fontWeight[fontWeight]};
  color: ${({ color = 'primary' }) => getStylesByColor(color)};
  ${({ size = 'l' }) => {
    const { fontSize, lineHeight, fontWeight, mobile } = getStylesBySize(size)
    return css`
      font-size: ${fontSize};
      line-height: ${lineHeight};
      font-weight: ${fontWeight};
      ${mobile &&
      css`
        @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
          font-size: ${mobile.fontSize};
          line-height: ${mobile.lineHeight};
          font-weight: ${mobile.fontWeight};
        }
      `}
    `
  }};

  ${({ lineEllipsis }) =>
    lineEllipsis &&
    css`
      -webkit-line-clamp: ${lineEllipsis};
      -webkit-box-orient: vertical;
      display: block;
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    `}

  ${({ nowrap }) => nowrap && 'white-space: nowrap;'}
`
