import { ReactElement, ReactNode } from 'react'
import { Tooltip } from 'antd'

import theme from 'styles/theme'

import { Icon } from 'designSystem/Icon'

interface FormItemPrefixProps {
  error?: string
  suffix?: ReactNode
}

export const getFormItemPrefix = ({ error, suffix }: FormItemPrefixProps): ReactElement | ReactNode => {
  return error ? (
    <Tooltip title={error} trigger="hover" placement="top" color={theme.colors.red[60]}>
      <Icon icon="warning" size={16} color={theme.colors.red[60]} hoverColor={theme.colors.neutral[0]} />
    </Tooltip>
  ) : (
    suffix
  )
}
