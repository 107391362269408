import { createGlobalStyle } from 'styled-components'

import { CropperStyles } from './cropperStyles'
import { NotificationStyles } from './notificationStyles'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fontFamily.primary}, sans-serif;
  
  }
  #root,
  .App{
    min-height: 100vh;
  }

  html,
  body {
    height: auto;
    width: 100%;
    min-height: 100%;
    color: ${({ theme }) => theme.colors.neutral[0]};
    // TODO change background color to gradient
    background: #212121;
  }

  a{
    text-decoration: none;
    
    span{
      &:hover{
      text-decoration: underline;
      }
    }
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  ul{
    margin: 0;
    padding: 0;
  }

  li{
    margin-left:20px;
  }


  // remove autofill background color in browsers
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
     -webkit-transition-delay: 9999s;
     transition-delay: 9999s;
  }

  .ant-empty-description{
    color: ${({ theme }) => theme.colors.neutral[5]} !important;
  }

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.colors.neutral[20]} ${({ theme }) => theme.colors.neutral[60]};
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.neutral[60]};
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutral[20]};
    border-radius: ${({ theme }) => theme.borderRadius.xl};
    border: 3px solid ${({ theme }) => theme.colors.neutral[0]};
  }


  ${NotificationStyles}
  ${CropperStyles}
  `
