import theme from 'styles/theme'

import { TypographySize } from '../types/typographySize'

export const getStylesBySize = (size: TypographySize) => {
  switch (size) {
    case 'm':
      return { fontSize: theme.fontSize.m, lineHeight: theme.lineHeight.m, fontWeight: theme.fontWeight.medium }
    case 'l':
      return { fontSize: theme.fontSize.l, lineHeight: theme.lineHeight.l, fontWeight: theme.fontWeight.regular }
    case 'xl':
      return { fontSize: theme.fontSize.xl, lineHeight: theme.lineHeight.xl, fontWeight: theme.fontWeight.medium }
    case 'h1':
      return {
        fontSize: theme.headers.desktop.fontSize.h1,
        lineHeight: theme.headers.desktop.lineHeight.h1,
        fontWeight: theme.fontWeight.bold,
        mobile: {
          fontSize: theme.headers.mobile.fontSize.h1,
          lineHeight: theme.headers.mobile.lineHeight.h1,
          fontWeight: theme.fontWeight.bold,
        },
      }
    case 'h2':
      return {
        fontSize: theme.headers.desktop.fontSize.h2,
        lineHeight: theme.headers.desktop.lineHeight.h2,
        fontWeight: theme.fontWeight.bold,
        mobile: {
          fontSize: theme.headers.mobile.fontSize.h2,
          lineHeight: theme.headers.mobile.lineHeight.h2,
          fontWeight: theme.fontWeight.bold,
        },
      }
    case 'h3':
      return {
        fontSize: theme.headers.desktop.fontSize.h3,
        lineHeight: theme.headers.desktop.lineHeight.h3,
        fontWeight: theme.fontWeight.semibold,
        mobile: {
          fontSize: theme.headers.mobile.fontSize.h3,
          lineHeight: theme.headers.mobile.lineHeight.h3,
          fontWeight: theme.fontWeight.bold,
        },
      }
    case 'h4':
      return {
        fontSize: theme.headers.desktop.fontSize.h4,
        lineHeight: theme.headers.desktop.lineHeight.h4,
        fontWeight: theme.fontWeight.semibold,
        mobile: {
          fontSize: theme.headers.mobile.fontSize.h4,
          lineHeight: theme.headers.mobile.lineHeight.h4,
          fontWeight: theme.fontWeight.bold,
        },
      }
    case 'h5':
      return {
        fontSize: theme.headers.desktop.fontSize.h5,
        lineHeight: theme.headers.desktop.lineHeight.h5,
        fontWeight: theme.fontWeight.semibold,
        mobile: {
          fontSize: theme.headers.mobile.fontSize.h5,
          lineHeight: theme.headers.mobile.lineHeight.h5,
          fontWeight: theme.fontWeight.bold,
        },
      }
    case 'h6':
      return {
        fontSize: theme.headers.desktop.fontSize.h6,
        lineHeight: theme.headers.desktop.lineHeight.h6,
        fontWeight: theme.fontWeight.semibold,
        mobile: {
          fontSize: theme.headers.mobile.fontSize.h6,
          lineHeight: theme.headers.mobile.lineHeight.h6,
          fontWeight: theme.fontWeight.bold,
        },
      }

    default:
      return { fontSize: theme.fontSize.l, lineHeight: theme.lineHeight.l, fontWeight: theme.fontWeight.regular }
  }
}
