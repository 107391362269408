import { Pagination } from 'antd'
import { styled } from 'styled-components'

export const PaginationItem = styled(Pagination)`
  &&&& .ant-pagination-item {
    a {
      color: ${({ theme }) => theme.colors.neutral[20]};
      &:hover {
        color: ${({ theme }) => theme.colors.primary.blue[60]};
      }
    }
  }

  &&&& .ant-pagination-item-active {
    border-color: ${({ theme }) => theme.colors.primary.blue[60]};
    background: transparent;
    a {
      color: ${({ theme }) => theme.colors.primary.blue[60]};
    }
  }
  &&& .ant-pagination-item-link,
  &&& .ant-pagination-item-ellipsis {
    color: ${({ theme }) => theme.colors.neutral[20]};
    svg {
      color: ${({ theme }) => theme.colors.neutral[20]};
    }
    &:hover {
      svg {
        color: ${({ theme }) => theme.colors.primary.blue[60]};
      }
    }
  }
`
