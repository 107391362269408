import { Link } from 'react-router-dom'

import { ExercisesType } from 'enums'
import { route } from 'constant'

import { Icon } from 'designSystem'

import { equipmentGroupItems } from './equipmentGroupItems'
import { muscleGroupItems } from './muscleGroupItems'

export const exercisesMenuItems = [
  {
    label: <Link to={`${route.exercises}`}>Muscle Groups</Link>,
    key: `${ExercisesType.Muscle}`,
    icon: <Icon icon="activity" />,
    children: muscleGroupItems(),
  },
  {
    label: <Link to={`${route.exercises}`}>Equipment</Link>,
    key: `${ExercisesType.Equipment}`,
    icon: <Icon icon="dumbbells" />,
    children: equipmentGroupItems(),
  },
]
