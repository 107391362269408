import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppEvent } from 'enums'
import { route } from 'constant'
import { invalidateRelatedQueries } from 'utils/invalidateRelatedQueries'

import { showNotification } from 'designSystem'

import { ExerciseValues } from '../../../utils/validationSchema'

import { useCreateExerciseMutation } from 'generated'

export const useCreateExercise = () => {
  const navigate = useNavigate()
  const [create, { loading }] = useCreateExerciseMutation({
    onCompleted: () => {
      navigate(route.exercises)
      invalidateRelatedQueries(AppEvent.ExerciseCreate)
      showNotification({ title: 'Exercise created', type: 'success' })
    },
  })

  const handleCreate = useCallback(
    (values: ExerciseValues) => {
      create({
        variables: {
          createExerciseInput: {
            isPublic: values.isPublic,
            image: values.image,
            description: values.description,
            name: values.name,
            bothSides: values.bothSides,
            restTime: values.restTime,
            equipments: values.equipments,
            muscles: values.muscles,
            healthConsiderations: values.healthConsiderations,
          },
        },
      })
    },
    [create],
  )

  return { loading, create: handleCreate }
}
