import { route } from 'constant'

export const breadcrumbs = [
  {
    name: 'Exercises',
    link: route.exercises,
  },
  {
    name: 'Add Exercise',
    link: route.exerciseCreate,
  },
]
