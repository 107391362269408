import { Divider as BaseDivider } from 'antd'
import { DividerProps as BaseDividerProps } from 'antd/lib/divider'
import { styled } from 'styled-components'

import { SpacerLevel } from 'types'

interface DividerProps extends BaseDividerProps {
  vertical?: boolean
  my?: SpacerLevel
  mx?: SpacerLevel
  mt?: SpacerLevel
  mb?: SpacerLevel
}

export const Divider = styled(BaseDivider)<DividerProps>`
  background-color: transparent;

  margin-top: ${({ mt, theme }) => mt && theme.spacers[mt]};
  margin-bottom: ${({ mb, theme }) => mb && theme.spacers[mb]};
  margin: ${({ my, mx, theme }) => {
    if (my) {
      return `${theme.spacers[my]} 0`
    }
    if (mx) {
      return `0 ${theme.spacers[mx]}`
    }
  }};

  &&&.ant-divider {
    &::after,
    &::before {
      border: 1px solid ${({ theme }) => theme.colors.neutral[30]};
    }
  }

  &&& .ant-divider-inner-text {
    color: ${({ theme }) => theme.colors.neutral[20]};
  }
`
