import { FC, PropsWithChildren } from 'react'
import { Link as BaseLink } from 'react-router-dom'

import theme from 'styles/theme'

import { Container } from 'designSystem/Container'
import { Icon } from 'designSystem/Icon'
import { Typography } from 'designSystem/Typography'

import * as Styled from './styles'

interface LinkProps {
  loading?: boolean
  to?: string
  onClick?: () => void
}

export const Link: FC<PropsWithChildren<LinkProps>> = ({ loading, onClick, to, children }) => {
  if (to) {
    return (
      <BaseLink to={to}>
        <Typography color="link">{children}</Typography>
      </BaseLink>
    )
  }
  return (
    <Styled.Link loading={loading} onClick={loading ? undefined : onClick}>
      <Container display="inline-flex" ai="center" gap="4">
        {loading && <Icon icon="spinner" spin color={theme.colors.primary.main} />}
        <Typography color="link">{children}</Typography>
      </Container>
    </Styled.Link>
  )
}
