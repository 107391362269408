import { styled } from 'styled-components'

import { Container as BaseContainer } from 'designSystem/Container'

export const Content = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Wrapper = styled.div<{ centered: boolean; maxWidth: number }>`
  max-width: ${({ maxWidth }) => maxWidth}px;
  width: 100%;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`

export const Container = styled(BaseContainer)`
  min-height: calc(100vh - 56px);
`
