import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { apolloClient } from 'config'
import { route } from 'constant'
import { useSetUser, useUser } from 'store'

import { useGetMeQuery } from 'generated'

interface UseGetMeProps {
  skip: boolean
}

export const useGetMe = ({ skip }: UseGetMeProps) => {
  const isLoggedIn = !!useUser()
  const setUserData = useSetUser()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { data, loading: isGetMeLoading, ...rest } = useGetMeQuery({ skip })

  useEffect(() => {
    if (data?.me) {
      setUserData(data?.me)
      setLoading(false)
    }
  }, [data?.me, setUserData])

  useEffect(() => {
    if (rest.error) {
      setLoading(false)
      navigate(route.login)
      apolloClient.clearStore()
    }
  }, [rest.error, navigate])

  useEffect(() => {
    if (!skip && !isLoggedIn) {
      setLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, isLoggedIn])

  return {
    data: data?.me,
    isLoggedIn,
    loading: isGetMeLoading || loading,
    ...rest,
  }
}
