import { FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import { route } from 'constant'

import { Container, Icon } from 'designSystem'

import * as Styled from './styles'

interface UnauthorizedLayoutProps {
  centered?: boolean
  maxWidth?: number
}

export const UnauthorizedLayout: FC<PropsWithChildren<UnauthorizedLayoutProps>> = ({
  children,
  centered = true,
  maxWidth = 440,
}) => {
  return (
    <Styled.Container ai="center" jc="center" pa={8}>
      <Styled.Container fd="column" maxWidth={maxWidth} {...(centered && { jc: 'center', ai: 'center' })}>
        <Container mb={12}>
          <Link to={route.login}>
            <Icon icon="logo" />
          </Link>
        </Container>
        <Styled.Wrapper maxWidth={maxWidth} centered={centered}>
          {children}
        </Styled.Wrapper>
      </Styled.Container>
    </Styled.Container>
  )
}
