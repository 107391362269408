import { Input as BaseInput } from 'antd'
import { css, styled } from 'styled-components'

import type { InputProps } from './Input'

export const Wrapper = styled.div<Pick<InputProps, 'width'>>`
  width: ${({ width }) => (width ? `${width}px;` : '100%')};
  position: relative;
`

const InputStyles = css`
  &&&&&&.ant-input,
  &&&&&&.ant-input-affix-wrapper {
    border-radius: ${({ theme }) => theme.borderRadius.m};
  }

  &&&&&&&&&&.ant-input-status-error,
  &&&&&&&&&&.ant-input-affix-wrapper-status-error {
    border-color: ${({ theme }) => theme.colors.red[60]};
    &:hover {
      border-color: ${({ theme }) => theme.colors.red[60]};
    }
  }
  &&&&&&&&.ant-input:focus-within,
  &&&&&&&&.ant-input-affix-wrapper:focus-within {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
  svg {
    fill: ${({ theme }) => theme.colors.neutral[20]};
  }
`

export const Input = styled(BaseInput)`
  ${InputStyles};
`

export const InputPassword = styled(BaseInput.Password)`
  ${InputStyles}
`
