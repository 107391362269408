import { css, styled } from 'styled-components'

export const Link = styled.span<{
  loading?: boolean
}>`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  ${({ loading }) =>
    loading &&
    css`
      cursor: not-allowed;
    `}
  :hover {
    text-decoration: none;
  }
`
