import { WeekDay } from 'generated'

export const weekDayOptions = [
  {
    value: WeekDay.Monday,
    label: 'Monday',
  },
  {
    value: WeekDay.Tuesday,
    label: 'Tuesday',
  },
  {
    value: WeekDay.Wednesday,
    label: 'Wednesday',
  },
  {
    value: WeekDay.Thursday,
    label: 'Thursday',
  },
  {
    value: WeekDay.Friday,
    label: 'Friday',
  },
  {
    value: WeekDay.Saturday,
    label: 'Saturday',
  },
  {
    value: WeekDay.Sunday,
    label: 'Sunday',
  },
]
