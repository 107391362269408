import { styled } from 'styled-components'

import { Container } from 'designSystem'

export const IconWrapper = styled(Container)`
  min-width: 52px;
  height: 52px;
  position: relative;
`

export const NotificationCount = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors.red[80]};
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.colors.red[10]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.red[80]};
  top: 2px;
  right: 2px;
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`
