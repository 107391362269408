import { useEffect } from 'react'

import { useSetBreadcrumbs } from 'store'

import type { Breadcrumb } from 'components'

export const useChangeBreadcrumbs = (breadcrumbs: Breadcrumb[]) => {
  const setBreadCrumbs = useSetBreadcrumbs()
  useEffect(() => {
    setBreadCrumbs(breadcrumbs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbs])
}
