import { FC, PropsWithChildren } from 'react'

import { Container } from 'designSystem'
import { Header } from 'components/Header'
import { Sidebar } from 'components/Sidebar'

import { sidebarItems } from './constants'

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container pa={9} bg="neutral.60" minHeight="100vh">
      <Sidebar sidebarItems={sidebarItems} />
      <Container fd="column" width="100%">
        <Header />
        <Container mt={6} pa={7} bg="neutral.50" height="100%" br="l">
          {children}
        </Container>
      </Container>
    </Container>
  )
}
