import { InputNumber as BaseInputNumber } from 'antd'
import { styled } from 'styled-components'

import type { InputNumberProps } from './InputNumber'

export const InputNumber = styled(BaseInputNumber)<InputNumberProps>`
  width: 100%;
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }
`

export const Wrapper = styled.div<{ $width?: number }>`
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
`
