import { gql } from '@apollo/client'

export const GET_ME = gql`
  query getMe {
    me {
      userName
      email
      role
      _id
      createdAt
      updatedAt
      googleId
      isVerified
    }
  }
`
