import { FC, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useForm, useGetSearchParams } from 'hooks'
import { route } from 'constant'

import { Button, Container, Icon, Input, Typography } from 'designSystem'
import { FormItem, UnauthorizedLayout } from 'components'

import { useResetPassword } from './hooks/useResetPassword'
import { ResetPasswordValues, validationSchema } from './utils/validationSchema'

export const ResetPassword: FC = () => {
  const { token } = useGetSearchParams()
  const navigate = useNavigate()
  const { resetPassword, loading } = useResetPassword({ token: String(token) })
  const { Form, handleSubmit } = useForm<ResetPasswordValues>({
    validationSchema,
  })

  const onSubmit = useCallback(
    (variables: ResetPasswordValues) => {
      resetPassword(variables)
    },
    [resetPassword],
  )

  useEffect(() => {
    if (!token) {
      navigate(route.login)
    }
  }, [token, navigate])

  return (
    <UnauthorizedLayout>
      <Typography as="h3" size="h3" color="white">
        Create new password
      </Typography>
      <Container mt={4} mb={9} jc="center">
        <Typography size="l">Please enter your new password</Typography>
      </Container>
      <Form>
        <Container fd="column" gap={16}>
          <FormItem label="Password" name="password">
            <Input hideChars placeholder="Password" prefix={<Icon size={16} icon="lock" />} />
          </FormItem>
          <FormItem label="Confirm Password" name="confirmPassword">
            <Input hideChars placeholder="Confirm Password" prefix={<Icon size={16} icon="lock" />} />
          </FormItem>
        </Container>

        <Container fd="column" mt={7}>
          <Button onClick={handleSubmit(onSubmit)} htmlType="submit" size="small" type="primary" loading={loading}>
            Confirm
          </Button>
        </Container>
      </Form>
    </UnauthorizedLayout>
  )
}
