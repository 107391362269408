import { route } from 'constant'

export const breadcrumbs = [
  {
    name: 'Trainings',
    link: route.trainings,
  },
  {
    name: 'Add Workout Program',
    link: route.trainingCreate,
  },
]
