import { exerciseRoutes, route } from 'constant'

export const getBreadcrumbs = (exerciseName?: string, id?: string) => {
  const exerciseLink = `${route.exercises}/${id}`
  return [
    {
      name: 'Exercises',
      link: route.exercises,
    },
    {
      name: exerciseName,
      link: exerciseLink,
    },
    {
      name: 'Edit',
      link: `${exerciseLink}${exerciseRoutes.edit}`,
    },
  ]
}
