import { Link } from 'react-router-dom'

import { route } from 'constant'

import { Icon } from 'designSystem'

export const sidebarItems = [
  {
    label: <Link to={route.dashboard}>Dashboard</Link>,
    key: route.dashboard,
    icon: <Icon icon="widgets" />,
  },
  {
    label: <Link to={route.trainings}>Trainings</Link>,
    key: route.trainings,
    icon: <Icon icon="muscle" />,
  },
  {
    label: <Link to={route.exercises}>Exercises</Link>,
    key: route.exercises,
    icon: <Icon icon="dumbbell" />,
  },
  {
    label: <Link to={route.profile}>Profile</Link>,
    key: route.profile,
    icon: <Icon icon="user" />,
  },
]
