import { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { route } from 'constant'

import { Button, Container, Icon, Input, Link, Typography } from 'designSystem'
import { FormItem, UnauthorizedLayout } from 'components'

import { useForgotPassword } from './hooks/useSignIn'
import { ForgotPasswordValues, validationSchema } from './utils/validationSchema'
import { ResendResetPassword } from '../elements/ResendResetPassword'

export const ForgotPassword: FC = () => {
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined)
  const form = useForm<ForgotPasswordValues>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const { forgotPassword, loading } = useForgotPassword({ setUserEmail })

  const onSubmit = form.handleSubmit((variables) => {
    forgotPassword(variables)
  })

  if (userEmail) {
    return <ResendResetPassword email={userEmail} />
  }

  return (
    <UnauthorizedLayout>
      <Typography as="h3" size="h3" color="white">
        Forgot password?
      </Typography>
      <Container mt={4} mb={9} jc="center">
        <Typography size="l">Enter your details to receive a reset link</Typography>
      </Container>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <FormItem label="Email" name="email">
            <Input placeholder="Email" prefix={<Icon size={16} icon="mail" />} />
          </FormItem>
          <Container fd="column" mt={7}>
            <Button htmlType="submit" size="small" type="primary" loading={loading}>
              Send Link
            </Button>
          </Container>
          <Container jc="center" mt={9} gap={4}>
            <Typography color="secondary">Back to</Typography>
            <Link to={route.login}>Sign In</Link>
            <Typography color="secondary">page</Typography>
          </Container>
        </form>
      </FormProvider>
    </UnauthorizedLayout>
  )
}
