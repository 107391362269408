import Cookies from 'js-cookie'
import dayjs from 'dayjs'

export interface Token {
  token: string
  expiresIn: number
}

export interface RefreshTokenPayload {
  refreshToken: string
}

export interface TokenResponse {
  accessToken: Token
  refreshToken: Token
}

function getDays(expiresIn: number) {
  return dayjs().add(expiresIn, 'second').toDate()
}

export function getAccessToken(): string | undefined {
  return Cookies.get('access_token')
}

export function setAccessToken(token: Token) {
  const expireInDays = getDays(token.expiresIn)
  Cookies.set('access_token', token.token, { expires: expireInDays })
}

export function setRefreshToken(token: Token) {
  const expireInDays = getDays(token.expiresIn)
  return Cookies.set('refresh_token', token.token, { expires: expireInDays })
}

export function setTokens({ accessToken, refreshToken }: TokenResponse) {
  setAccessToken(accessToken)
  setRefreshToken(refreshToken)
}

export function getRefreshToken(): string | undefined {
  return Cookies.get('refresh_token')
}

export function removeTokens() {
  Cookies.remove('access_token')
  Cookies.remove('refresh_token')
}

export const isAuthenticated = (): boolean => !!getAccessToken() || !!getRefreshToken()
