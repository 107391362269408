import { Link } from 'react-router-dom'

import { ExercisesType } from 'enums'
import { getEquipmentOptionsLabel, route } from 'constant'

import { Equipment } from 'generated'

export const equipmentGroupItems = () => {
  return Object.values(Equipment).map((equipment) => ({
    label: (
      <Link to={`${route.exercises}?${ExercisesType.Equipment}=${equipment}`}>
        {getEquipmentOptionsLabel(equipment)}
      </Link>
    ),
    key: equipment,
  }))
}
