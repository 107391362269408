import { FC } from 'react'
import type { ImageProps as BaseImageProps } from 'antd'
import { Image as BaseImage } from 'antd'

import { BorderRadius } from 'types'

import * as Styled from './styles'

export type ImageProps = BaseImageProps & {
  borderRadius?: BorderRadius
}

export const Image: FC<ImageProps> = ({ borderRadius, ...props }) => {
  return (
    <Styled.ImgWrapper $borderRadius={borderRadius}>
      <BaseImage {...props} />
    </Styled.ImgWrapper>
  )
}
