import { Upload } from 'antd'
import { css, styled } from 'styled-components'

export const BaseUpload = styled(Upload)`
  &&&& .ant-btn,
  &&&& .ant-upload {
    width: 100%;
  }

  &&&& .ant-upload-list-item {
    color: ${({ theme }) => theme.colors.neutral[0]};
    svg {
      fill: ${({ theme }) => theme.colors.neutral[0]};
    }
  }
`

export const Wrapper = styled.div<{ $error: boolean }>`
  ${({ $error }) =>
    $error &&
    css`
      &&&& .ant-upload-list-item {
        color: ${({ theme }) => theme.colors.red[60]};
        svg {
          fill: ${({ theme }) => theme.colors.red[60]};
        }
      }
      &&&&&&&&&& .ant-btn {
        border: 1px solid ${({ theme }) => theme.colors.red[60]};
      }
    `}
`
