import { FC, forwardRef, Ref } from 'react'
import { Switch as AntdSwitch } from 'antd'
import type { SwitchProps as BaseSwitchProps } from 'antd/lib'

import { typedMemo } from 'types'

import * as Styled from './styles'

export type SwitchProps = BaseSwitchProps & {
  value?: boolean
}

export const BaseSwitch: FC<SwitchProps> = forwardRef(({ value, ...props }, ref: Ref<HTMLElement>) => {
  return (
    <Styled.Wrapper>
      <AntdSwitch ref={ref} checked={!!value} {...props} />
    </Styled.Wrapper>
  )
})

export const Switch = typedMemo(BaseSwitch)
