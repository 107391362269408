import { CSSProperties, FunctionComponent, ReactElement, SVGAttributes } from 'react'

import { typedMemo } from 'types'

import { iconToComponent } from './constants/iconToComponent'
import * as Styled from './styles'

type IconSVGProps = SVGAttributes<unknown>

export type IconName = keyof typeof iconToComponent

export interface IconProps extends IconSVGProps {
  icon: IconName
  size?: number
  spin?: boolean
  hoverColor?: string
}

export const IconBase = ({ icon, size, spin, className, hoverColor, ...props }: IconProps): ReactElement => {
  const IconComponent = iconToComponent[icon] as FunctionComponent

  const iconStyle: CSSProperties = {
    ...(!props.width && size && { width: size, height: 'auto' }),
  }

  return (
    <Styled.IconWrapper
      className={className}
      $clickable={!!props.onClick}
      spin={spin}
      color={props.color}
      hoverColor={hoverColor}
    >
      <IconComponent style={iconStyle} {...props} />
    </Styled.IconWrapper>
  )
}

export const Icon = typedMemo(IconBase)
