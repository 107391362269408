import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import theme from 'styles/theme'

import { Button, Container, Icon, Typography } from 'designSystem'

import * as Styled from './styles'

interface CreateBlockProps {
  title: string
  text: string
  buttonText: string
  link: string
}

export const CreateBlock: FC<CreateBlockProps> = ({ title, text, buttonText, link }) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(link)
  }, [navigate, link])
  return (
    <Styled.Container gap={8} maxWidth={300}>
      <Icon icon="addCircle" color={theme.colors.blue[60]} />
      <Container fd="column" gap={8}>
        <Typography fontWeight="bold" color="white" size="xl">
          {title}
        </Typography>
        <Typography>{text}</Typography>
        <Button size="small" onClick={handleClick}>
          {buttonText}
        </Button>
      </Container>
    </Styled.Container>
  )
}
